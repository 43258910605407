import { Data } from "@angular/router";
import {
  BehaviorSubject,
  map,
  Observable,
  ReplaySubject,
  scan,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  tap,
} from "rxjs";

type EditableRowAction<T extends Record<string, any>, K extends keyof T> =
  | {
      type: "UPDATE";
      payload: {
        key: K;
        value: T[K];
      };
    }
  | {
      type: "SET";
      payload: Partial<T>;
    }
  | {
      type: "SELECT_CELL";
      payload: K | undefined;
    };

type EditableRowState<T extends Record<string, any>, K extends keyof T> = {
  rowData: Partial<T>;
  currentCell: K | undefined;
};

const EditableRowStateReducer = <
  T extends Record<string, any>,
  K extends keyof T = string
>(
  state: EditableRowState<T, K>,
  action: EditableRowAction<T, K>
): EditableRowState<T, K> => {
  switch (action.type) {
    case "UPDATE": {
      return {
        ...state,
        rowData: {
          ...state.rowData,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "SET": {
      return {
        rowData: action.payload,
        currentCell: undefined,
      };
    }

    case "SELECT_CELL": {
      return {
        ...state,
        currentCell: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export class EditableRow<DATA extends Record<string, any>> {
  private readonly EditiableRowInitialValue: EditableRowState<
    DATA,
    keyof DATA
  > = {
    rowData: {},
    currentCell: undefined,
  };
  private subscription = new Subscription();
  private action$ = new ReplaySubject<EditableRowAction<DATA, keyof DATA>>(1);
  private state$ = this.action$.pipe(
    scan(
      EditableRowStateReducer<DATA, keyof DATA>,
      this.EditiableRowInitialValue
    ),
    startWith(this.EditiableRowInitialValue),
    shareReplay(1)
  );


  onCancel?: () => void;
  onSave?: (data: Partial<Data>) => void;
  rowData: Partial<DATA>;
  currentCell: keyof DATA | undefined;
  isValid: boolean;

  constructor(
    initialValue: Partial<DATA>,
    validator: (val: Partial<DATA>) => boolean
  ) {
    this.subscription.add(this.state$.subscribe((state) => {
        this.isValid = validator(state.rowData);
        this.rowData = state.rowData;
        this.currentCell = state.currentCell;
    }));
    this.setValue(initialValue);
  }

  setValue(initialValue: Partial<DATA>, onCancel?: () => void, onSave?: (data: Partial<Data>) => void) {
    this.action$.next({ type: "SET", payload: initialValue });
    this.onCancel = onCancel;
    this.onSave = onSave;
  }

  updateValue<K extends keyof DATA>(key: K, value: DATA[K]) {
    this.action$.next({ type: "UPDATE", payload: { key, value } });
  }

  getValue<K extends keyof DATA>(key: K) {
    return this.rowData[key];
  }

  getValues() {
    return this.rowData;
  }

  startEdit(key: keyof DATA | undefined = undefined) {
    this.action$.next({ type: "SELECT_CELL", payload: key });
  }

  cleanup() {
    this.subscription.unsubscribe();
  }

  save(){
    this.onSave?.(this.rowData);
  }

  cancel(){
    this.onCancel?.();
  }
}

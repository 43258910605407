import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicePoint } from 'src/app/shared/models/servicePoint';
import { ServicePointsService } from '../service-points/service-points.service';

@Component({
  selector: 'app-service-point-summary',
  templateUrl: './service-point-summary.component.html',
  styleUrl: './service-point-summary.component.scss'
})
export class ServicePointSummaryComponent implements OnInit {
  public referenceDate: string;
  public servicePointId: string;
  public servicePoint: ServicePoint;
  
  constructor(    
    private service: ServicePointsService,
    private route: ActivatedRoute){
  }

  ngOnInit(): void {
    this.referenceDate = this.route.snapshot.queryParamMap.get('reference_date')!;
    this.servicePointId = this.route.snapshot.queryParamMap.get('service_point_id')!;
    this.getServicePoint();
  }

  getServicePoint() {
    this.service.getServicePoint(0, 1, this.servicePointId).subscribe(

      (data : any) => {
        if (data.total === 0 || data.total > 1) {
          alert("Error getting service point data")
        }
        this.servicePoint = data.results[0];
      }
    );
  }
}

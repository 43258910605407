import { map, Observable, scan, shareReplay, startWith, Subject } from "rxjs";
import { FilterParams } from "./http-params";

type FilterEvent<T> = {
    type: "ADD" | "REMOVE";
    value: T;
} | {
  type: 'CLEAR'
}

export class FilterListObservable<T, K extends Record<string, any> = any> {
  private events$ = new Subject<FilterEvent<T>>();
  public list$: Observable<T[]>;
  public params$: Observable<FilterParams>;

  constructor(
    private name: keyof K,
    private getId: (item: T) => string
  ) {
    this.list$ = this.events$.pipe(
        scan((acc, evt) => {
            switch(evt.type) {
                case 'ADD': {
                    const updated = acc.map(i => this.getId(i) === this.getId(evt.value) ? evt.value : i);
                    const alreadyIn = !!updated.find(i => this.getId(i) === this.getId(evt.value));
                    return alreadyIn ? updated: [...updated, evt.value];
                }
    
                case 'REMOVE': {
                    return acc.filter(i => this.getId(i) !== this.getId(evt.value));
                }

                case 'CLEAR': {
                  return [];
              }

                default: return acc;
            }
        }, [] as T[]),
        startWith([] as T[]),
        shareReplay(1)
    );
    this.params$ = this.list$.pipe(
        map(l => ({
            name: this.name as string,
            values: l.map(i => ({
                value: this.getId(i)
            }))
        }))
    );
  }

  add(item: T) {
    this.events$.next({type: 'ADD', value: item});
  }
  
  remove(item: T) {
    this.events$.next({type: 'REMOVE', value: item});
  }

  clear() {
    this.events$.next({type: 'CLEAR'});
  }
}

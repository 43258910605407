import { Component, Input } from "@angular/core";
import {
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest,
  tap,
  map,
  switchMap,
  catchError,
  NEVER,
  of,
  shareReplay,
} from "rxjs";
import { FilterListObservable } from "src/app/shared/utilities/filter-list-observable";
import {
  FilterParams,
  PaginationParams,
} from "src/app/shared/utilities/http-params";

@Component({
  styles: `
  :host{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 250px;

    .scroll-container {
        width: 100%;
        max-height: 10rem;
        min-height: 6rem;
        overflow-y: scroll;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .search-container {     
        position: relative;
        width: 100%;
        padding: 0.5rem 0.75rem;
        
        input {
            width: 100%;
            padding: 0.5rem 1.75rem 0.25rem 0.25rem;
            border: none;
            border-bottom: 1px solid black;
            font-size: 0.9rem;
            line-height: 1.25rem;

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            position: absolute;
            top: 0.75rem;
            right: 0.75rem;
        }

        label {
            position: absolute;
            top: 1rem;
            left: 1rem;
            opacity: 0.75;
            width: 0;
            white-space: nowrap;
        }

        input::placeholder {
            opacity: 0;
        }

        input:not(:placeholder-shown) + label {
            transform: scale(0.75) translateY(-1.25rem);
        }
    }

    hr{
        width: calc(100% - 1rem);
        margin: 0.25rem 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            margin: 0;
            width: 100%;
            background: white;

            .checkbox {
                display: inline-block;
                position: relative;
                min-width: 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                border: 1px solid lightgray;
                border-radius: 0.25rem;
                background-color: white;
                margin-right: 0.5rem;
            }

            &.selected {
                background: whitesmoke;
                .checkbox {
                    background-color: rgba(var(--bs-primary-rgb), 1);
                    &::before {
                        display: block;
                        position: absolute;
                        content: ' ';
                        width: 1.25rem;
                        height: 0.75rem;
                        transform: rotate(-45deg);
                        left: 0.1rem;
                        top: -0.12rem;
                        box-shadow: -3px 3px 1px white;
                    }
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                padding: 0.5rem 1.5rem;
                border: none;
                background: transparent;
                font-size: 0.9rem;
                line-height: 1.25rem;
                text-align: left;
                overflow: hidden;

                &.search-option {
                  align-items: end;
                }
            }
        }
    }

    .spinner-container {
        padding: 2.5rem 0;
    }

    .paging-text {
      margin-left: 1rem;
      font-weight: normal;
      color: #2bb348;
      animation: pulse 2s linear infinite;;
    }

    ul li.error {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: #e02020;
      text-align: center;
      text-wrap: auto;
      margin: 1rem;
      max-width: calc(100% - 2rem);
      overflow: hidden;
      font-weight: normal;

      p {
        margin: 0;
      }
    }

    .option-with-disclaimer {
      display: flex;
      flex-direction: column;

      .disclaimer {
        font-size: 0.7rem;
        line-height: 0.5rem;
        color: gray;
      }
    }

    @keyframes pulse {
      from { opacity: 1; }
      50% { opacity: 0; }
      to { opacity: 1; }
    }
  }
    `,
  template: `
    @if(!!toFilterParams) {
    <div class="search-container">
      <mat-icon class="search-icon" svgIcon="search"></mat-icon>
      <input
        type="text"
        [id]="id"
        [placeholder]="'none'"
        [ngModel]="search"
        (keyup)="onSearch($event)"
      />
      <label [for]="id">{{ label }}</label>
    </div>
    }
    <div class="scroll-container" (scroll)="onScroll($event)">
      <ul>
        @if(allowPartial && search) {
        <li [class.selected]="isPartialSelected(search)">
          <button
            class="search-option"
            (click)="onPartialSelect($event, search, isPartialSelected(search))"
          >
            @if(multi) {
            <span class="checkbox">&nbsp;</span>
            }
            <div class="option-with-disclaimer">
              <span class="disclaimer">current search</span>
              <span>{{ search }}</span>
            </div>
          </button>
        </li>
        } @for (selectedValue of selectedValues; track toIdValue(selectedValue))
        { @if(searchToData && toIdValue(selectedValue) !==
        toIdValue(searchToData(search ?? '')) || !searchToData) {
        <li [class.selected]="true">
          <button (click)="onSelect($event, selectedValue, true)">
            @if(multi) {
            <span class="checkbox">&nbsp;</span>
            }
            {{ toDisplayValue(selectedValue) }}
          </button>
        </li>
        } }
      </ul>

      @if(selectedValues.length) {
      <hr />
      } @if(searching) {
      <div class="spinner-container">
        <app-spinner />
      </div>
      } @else {
      <ul>
        @for (datum of data; track toIdValue(datum)) { @if(!isSelected(datum)) {
        <li>
          <button (click)="onSelect($event, datum, false)">
            @if(multi) {
            <span class="checkbox">&nbsp;</span>
            }
            {{ toDisplayValue(datum) }}
          </button>
        </li>
        } } 
      </ul>
      }
    </div>
  `,
})
export class SelectFilterComponent<T> {
  @Input({ required: true }) id: string;
  @Input({ required: true }) label: string;
  @Input({ required: true }) selectedValues$: FilterListObservable<T>;
  @Input({ required: true }) fetchData: (
    pagination: PaginationParams,
    filters: FilterParams[]
  ) => Observable<T[]>;
  @Input({ required: true }) toDisplayValue: (data: T) => string;
  @Input({ required: true }) toIdValue: (data: T) => string;
  @Input() toFilterParams: (search: string | null) => FilterParams;
  @Input() multi: boolean = true;
  @Input() allowPartial: boolean = false;
  @Input() searchToData?: (search: string) => T;

  private subscription = new Subscription();

  public error = false;
  public paging = false;
  private currentPage = 0;
  private page$ = new BehaviorSubject<number>(0);
  private paginationParams$: Observable<PaginationParams> = this.page$.pipe(
    tap((page) => {
      this.paging = true;
      this.currentPage = page;
    }),
    map((page) => ({
      pageNumber: page,
      pageSize: 25,
    }))
  );

  public search: string | null = null;
  public searching = false;
  private search$ = new BehaviorSubject<string | null>(null);
  private filterParams$ = this.search$.pipe(
    tap((search) => {
      this.searching = true;
      this.search = search;
      this.data = [];
    }),
    map((search) => this.toFilterParams?.(search)),
    shareReplay(1)
  );

  public selectedValues: T[] = [];
  public data: T[] = [];
  private hasMore = true;
  private data$: Observable<T[]> = combineLatest([
    this.paginationParams$,
    this.filterParams$,
  ]).pipe(
    switchMap(([p, f]) => this.fetchData(p, [f])),
    tap((d) => {
      this.hasMore = d.length === 25;
      this.data = [...this.data, ...d];
      this.paging = false;
      this.searching = false;
      this.error = false;
    }),
    catchError(() => {
      this.error = true;
      this.paging = false;
      this.searching = false;
      return NEVER;
    })
  );

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    if (this.hasMore && reachedThreshold && !this.paging && !this.searching) {
      this.page$.next(this.currentPage + 1);
    }
  }

  onSearch(evt: KeyboardEvent) {
    this.page$.next(0);
    this.search$.next((evt.target as any).value ?? null);
  }

  onSelect(evt: Event, item: T, remove: boolean) {
    evt.stopPropagation();
    if (!this.multi) {
      this.selectedValues$.clear();
    }

    if (remove) {
      this.selectedValues$.remove(item);
    } else {
      this.selectedValues$.add(item);
    }
  }

  onPartialSelect(evt: Event, search: string, remove: boolean) {
    evt.stopPropagation();
    if (!this.searchToData) {
      return;
    }
    if (remove) {
      this.selectedValues$.remove(this.searchToData(search));
    } else {
      this.selectedValues$.add(this.searchToData(search));
    }
  }

  isSelected(item: T) {
    return this.selectedValues.find(
      (sv) => this.toIdValue(sv) === this.toIdValue(item)
    ) !== undefined;
  }

  isPartialSelected(search: string) {
    if (!this.searchToData) {
      return false;
    }
    return !!this.selectedValues.find(
      (sv) => this.toIdValue(sv) === this.toIdValue(this.searchToData!(search))
    );
  }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe());
    this.subscription.add(
      this.selectedValues$.list$.subscribe((sv) => {
        this.selectedValues = sv;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Component } from "@angular/core";
import { ToastService } from "./toasts.service";

@Component({
  selector: "app-toasts",
  template: `
    @for (toast of toastService.toasts; track toast) {
        <ngb-toast
            [className]="toast.type"
            [autohide]="true"
            [animation]="true"
            [delay]="2500"
            (hidden)="toastService.remove(toast)">
            {{ toast.message }}
        </ngb-toast>
    }
  `,
  styles: `
    :host {
        position: fixed;
        bottom: 12rem;
        display: flex;
        justify-content: space-around;
        margin: auto;
        width: 100%;
        z-index: 1250;

        & > * {
            width: 400px;
            padding: 1rem 2rem;
            border-radius: 0.25rem;
        }
    }

    .error {
        color: white;
        background-color: rgb(210, 51, 47)
    }

    .success {
        color: white;
        background-color: rgb(33, 135, 55)
    }
  `,
})
export class ToastsComponent {
  constructor(public toastService: ToastService) {}
}

import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-spinner",
  template: ` <div class="spokes-wrapper">
    @for (spoke of spokes; track $index) {
    <div class="spoke-wrapper">
      <div class="spoke"></div>
    </div>
    }
  </div>`,
  styles: `
    @use 'sass:color';

    @keyframes spin {
        0% {transform:rotate(0deg);}
        100% {transform:rotate(360deg);}
    }

    .spokes-wrapper {
        animation: spin 2s infinite linear;
    }
    .spoke-wrapper {
        position: absolute;
        border-radius: 50%;
        height: 0;
        width: 0;

        .spoke {
            position: relative;
            margin-left: 16px;
            margin-top: -2px;
            width: 16px;
            height: 4px;
            top: 0;
            left: 0;
            background-color: rgb(33, 135, 55);
        }

        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
            transform: rotate(#{($i - 1) * 30}deg);
            opacity:  ($i - 1) * 0.09;
            }
        }
    }
`,
})
export class SpinnerComponent {
  spokes = new Array(12).fill(void 0);
}

import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgbDatepickerModule, NgbDateAdapter, NgbDateNativeAdapter } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { DateStructPipe } from "src/app/shared/pipes/dateStruct.pipe";
import { DateFilterOperator, DateFilterState, FilterDateObservable } from "src/app/shared/utilities/filter-date-observable";

@Component({
    standalone: true,
    imports: [FormsModule, CommonModule, MatIconModule, NgbDatepickerModule, DateStructPipe],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
    styles: `
    :host {
        margin: 0.5rem;
    }

    .operation-btn-group {
        display:flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between;

        button {
            background: whitesmoke;
            border: 1px solid gainsboro;
            border-radius: 0;
            width: 100%;
            height: 2rem;

            &.selected {
                background: rgba(var(--bs-primary-rgb), 1);
                color: white;
            }

            &:first-child {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }

            &:last-child {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }

            &:not(:last-child) {
                border-right: none;
            }
        }
    }

    .app-datepicker::ng-deep {
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 0.9rem;
      line-height: 1.25rem;

      .ngb-dp-header {
        background-color: transparent;

        .ngb-dp-arrow{
          display: none;
        }
      }

      .ngb-dp-months {
        display: block;
        width: 100%;

        .ngb-dp-week {
          justify-content: space-between;
          display: none;

          &:has(.ngb-dp-day:not(.hidden)) {
            display: flex;
          }
        }
      }

      .ngb-dp-day {
        font-weight: 500;
      }

      .ngb-dp-weekdays {
        display: flex !important; 
        background-color: transparent;
        border: none;

        .ngb-dp-weekday {
          color: black;
          font-weight: 700;
        }
      }

      .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0; 
      }

      .ngb-dp-month:last-child .ngb-dp-week {
        padding-right:0; 
      }

      .ngb-dp-navigation-select {
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        select {
          width: 100%;
          padding: 0.5rem 1rem 0.25rem 1rem;
          position: relative;
          width: 100%;
          border: none;
          background: white;
          text-align: center;
          border-bottom: 1px solid black;
          border-radius: 0;
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          
        }
      }

      [ngbDatepickerDayView] {
        border-radius: 50%;
      }
      
      ngb-datepicker-month {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 0.75rem;
      }
    }
    `,
    template: `
        <div class="operation-btn-group">
            <button [class.selected]="operationSelected('<')" (click)="onOperationClick('<')">Before</button>
            <button [class.selected]="operationSelected('==')" (click)="onOperationClick('==')">Equals</button>
            <button [class.selected]="operationSelected('>')" (click)="onOperationClick('>')">After</button>
        </div>
        <ngb-datepicker
          #dp
          class="app-datepicker"
          [startDate]="selectedValues?.date | dateStruct"
          [ngModel]="selectedValues?.date"
          (ngModelChange)="onDateChange($event)"
          outsideDays="hidden"
        />
    `
})
export class DateFilterComponent {
    @Input({ required: true}) selectedValues$: FilterDateObservable;

    private subscription = new Subscription();
    public selectedValues?: DateFilterState;

    onOperationClick(op: Exclude<DateFilterOperator, '<=' | '>='>) {
        this.selectedValues$.setOp(op);
    }

    onDateChange(date: Date) {
        this.selectedValues$.setDate(date);
    }

    operationSelected(op: Exclude<DateFilterOperator, '<=' | '>='>) {
        return this.selectedValues?.operator === op || 
        (this.selectedValues?.operator === '<=' && ['<', '=='].includes(op)) ||
        (this.selectedValues?.operator === '>=' && ['>', '=='].includes(op))
    }

    ngOnInit() {
        this.subscription.add(this.selectedValues$.state$.subscribe(sv => {
            this.selectedValues = sv;
        }));
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})
export class TableHeaderOpenFilterService {
    private _opened$ = new BehaviorSubject<string | null>('');
    open(name: string) {
        this._opened$.next(name);
    }

    close() {
        this._opened$.next(null);
    }

    asObservable() {
        return this._opened$;
    }
}
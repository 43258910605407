import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-content",
  template: `
      <ng-content/>
      `,
  styles: `
    :host {
        position: relative;
        width: calc(100% - 6rem);
        margin: 1rem 3rem 0.5rem 3rem;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
  `,
})
export class PageContentComponent {
}

import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  CurrentFilter,
  Filters,
} from "../table.types";
import {
  distinctUntilChanged,
  interval,
  map,
  Observable,
  Subject,
  Subscription,
  tap,
  switchMap,
  NEVER,
} from "rxjs";
import { IHeaderAngularComp } from "ag-grid-angular";
import { TableHeaderOpenFilterService } from "./table-header-open-filter.service";
import { CurrentSort, TableSortObservable } from "src/app/shared/utilities/table-sort-observable";

@Component({
  selector: "app-table-header",
  template: `
    <button class="sort-btn" (click)="handleSort($event)">
      <span class="display-text">{{ display }}</span>

      @if(currentSort) {
      <span class="sort-indicator">
        @if(currentSort.direction === 'asc') {
        <mat-icon svgIcon="sort-asc" />
        } @else {
        <mat-icon svgIcon="sort-desc" />
        }
        <span class="order-indicator">
          @if(currentSort.order) {
          {{ currentSort.order }}
          } @else { &nbsp; }
        </span>
      </span>
      }
    </button>

    @if (filterable && filterComponent) {
    <button class="filter-btn" (click)="handleFilterClick()">
      <mat-icon svgIcon="filter" />
      @if(currentFilter && currentFilter.values.length) {
      <span class="filter-indicator">
        @if(currentFilter.values.length > 1) {
        {{ currentFilter.values.length }}
        } @else { &nbsp; }
      </span>
      }
    </button>
    }

    <div class="filter-container-outer" [ngStyle]="placementStyle">
      @if(open && filterComponent){
      <div class="filter-container-inner" [class.left]="leftAlignFilter">
        <ng-container
          *ngComponentOutlet="filterComponent; inputs: filterComponentParams"
        />
      </div>
      }
    </div>
  `,
  styles: `
    :host {
        position: relative;
        display: grid;
        grid-template-columns: 1fr minmax(0, 1.5rem);
        gap: 0.25rem;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        width: 100%;

        button {
            display: grid;
            align-items: center;
            background-color: transparent;
            border: none;
            padding: 0;
            gap: 0.25rem;
            white-space: nowrap;
        }

        .sort-btn {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1.5rem);

            .display-text {
              white-space: break-spaces;
              text-align: start;
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 2.5rem;
              line-height: 1.2rem;
            }
            
            .sort-indicator {
              position: relative;
              height: 24px;
            }
        }

        .filter-btn {
            position: relative;
            width: fit-content;
        }

        .sort-indicator, .filter-btn {
            * {
                position: relative;
                z-index: 1;
            }

            .order-indicator, .filter-indicator {
                position: absolute;
                font-size: 0.75rem;
                background-color: #2196F3;
                color: white;
                padding: 0 0.25rem;
                line-height: 1rem;
                min-width: 1rem;
                border-radius: 0.5rem;
                bottom: -0.25rem;
                left: 0.85rem;
                z-index: 0;
            }
        }


    .filter-container-outer {
      position: fixed;
      z-index: 100;
      overflow: visible;
    }
        
    .filter-container-inner {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      border: 1px solid black;
      background: white;

      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }

      &.left::before {
        right: unset;
        left: 1rem;
      }
    }
    }
    `,
})
export class TableHeaderComponent
  implements OnInit, OnDestroy, IHeaderAngularComp
{
  elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
  tableHeaderOpenFilterService = inject(TableHeaderOpenFilterService);

  @Input({ required: true }) name: string;
  @Input({ required: true }) display: string;
  @Input() sortable: boolean = true;
  @Input() sortOrder$: TableSortObservable;
  @Input() filterable: boolean = true;
  @Input() filters$: Observable<Filters> = new Subject();
  @Input() filterComponent: any;
  @Input() filterComponentParams: any = {};
  @Input() leftAlignFilter: boolean = false;

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    if(this.open && !this.elementRef.nativeElement.contains(event.target as Node)) {
      this.tableHeaderOpenFilterService.close();
    }
  }

  private subscription = new Subscription();

  private currentSort$: Observable<CurrentSort | undefined>;
  private currentFilter$: Observable<CurrentFilter>;

  placementStyle: Record<string, string>;
  public currentSort: CurrentSort | undefined;
  public currentFilter: CurrentFilter;
  public open = false;

  ngOnInit() {
    this.currentSort$ = this.sortOrder$.getCurrentSort(this.name);

    this.currentFilter$ = this.filters$.pipe(
      map((fs) => {
        const f = fs?.find((f) => f.name === this.name);
        return f && this.filterable ? f : null;
      })
    );

    this.subscription.add(
      this.currentSort$.subscribe((v) => {
        this.currentSort = v;
      })
    );

    this.subscription.add(
      this.currentFilter$.subscribe((v) => {
        this.currentFilter = v;
      })
    );

    this.subscription.add(
      this.tableHeaderOpenFilterService.asObservable().pipe(
        map(opened => opened === this.name),
        tap((open) => { this.open = open; }),
        switchMap(open => open ? interval(40) : NEVER),
        map(() => this.elementRef.nativeElement.getBoundingClientRect()),
        distinctUntilChanged(
          (a, b) => JSON.stringify(a) === JSON.stringify(b)
        ),
        tap((b) => {
            const windowWidth = document.documentElement.getBoundingClientRect().right;
            const rem = 16;
            if(this.leftAlignFilter) {
              const leftOffset = b.left - (0.75 * rem);
              if (leftOffset > 0 || leftOffset < windowWidth) {
                this.open = false;
              }
              this.placementStyle = {
                left: `${Math.ceil(leftOffset)}px`,
                top: `${Math.ceil(b.bottom + rem)}px`,
              };
            } else {
              const rightOffset = b.right + (1.5*rem);
              if (windowWidth - rightOffset < (2*rem) || rightOffset < (2*rem)) {
                this.open = false;
              }
              this.placementStyle = {
                right: `${Math.ceil(windowWidth - rightOffset)}px`,
                top: `${Math.ceil(b.bottom + rem)}px`,
              };
            }

        })
      ).subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  agInit(params: any): void {
    this.name = params.name;
    this.display = params.display;
    this.sortable = params.sortable;
    this.filterable = params.filterable;
    this.sortOrder$ = params.sortOrder$;
    this.filters$ = params.filters$;
    this.filterComponent = params.filterComponent;
    this.filterComponentParams = params.filterComponentParams;
    this.leftAlignFilter = params.leftAlignFilter;
  }

  refresh(params: any): boolean {
    this.agInit(params);
    return true;
  }

  handleSort(evt: MouseEvent) {
      this.sortOrder$.next({
          name: this.name,
          multi: evt.ctrlKey,
      });
  }

  handleFilterClick() {
    this.open 
    ? this.tableHeaderOpenFilterService.close()
    : this.tableHeaderOpenFilterService.open(this.name)
  }
}

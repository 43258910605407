import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  NEVER,
  Observable,
  of,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { Filters } from "./table.types";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { APIEnvelope } from "src/app/shared/models/api";
import * as moment from "moment";
import { TableHeaderComponent } from "./table-header/table-header.component";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { DateFilterComponent } from "./filters/select-filter/date-filter.component";
import { FilterDateObservable } from "src/app/shared/utilities/filter-date-observable";
import {
  EntityRef,
  ServicePointIdReference,
} from "src/app/shared/models/referenceData";
import { FilterListObservable } from "src/app/shared/utilities/filter-list-observable";
import { SelectFilterComponent } from "./filters/select-filter/select-filter.component";
import { FilterParams } from "src/app/shared/utilities/http-params";
import { titlecase } from "src/app/shared/utilities/titlecase";
import {
  SortOrder,
  TableSortObservable,
} from "src/app/shared/utilities/table-sort-observable";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { SpaOverrideService } from "../spah-overrides/spah-override.service";
import { SpaOverride } from "src/app/shared/models/spaOverride";
import { SpaOverrideAction } from "../spah-overrides/spah-override.types";
import { FlagCellComponent } from "./cells/flag-cell.component";
import { EditableRow } from "src/app/shared/utilities/editable-row";
import { BasicEntityRefCellComponent } from "./cells/basic-entity-code-cell.component";
import { ServicePointCellComponent } from "./cells/service-point-cell.component";
import { DateCellComponent } from "./cells/date-cell.component";
import { ActionCellComponent } from "./cells/action-cell.component";
import { isEqual } from "lodash";

@Component({
  standalone: true,
  selector: "app-spah-overrides-table",
  imports: [
    AgGridAngular,
    TablePaginationComponent,
  ],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1.5rem;
    }
  `,
  template: `
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 625px"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class SpahOverridesTableComponent implements OnDestroy {
  spaOverrideService = inject(SpaOverrideService);
  referenceDataService = inject(ReferenceDataService);

  @Input() public actions$: Subject<SpaOverrideAction>;

  @Output() public pageNumberChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();
  @Output() public sortOrderChange = new EventEmitter<SortOrder>();
  @Output() public filtersChange = new EventEmitter<Filters>();

  private subscription = new Subscription();
  public gridApi: GridApi;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageNumber, pageSize]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  // SORTING
  public sortOrder: SortOrder = [];
  public sortOrder$ = new TableSortObservable();

  // FILTERS
  public selectedServicePointId$ = new FilterListObservable<
    ServicePointIdReference,
    SpaOverride
  >("service_point_uid", (i) => i.service_point_id);
  public selectedEffectiveDate$ = new FilterDateObservable<SpaOverride>(
    "effective_date",
    "YYYY-MM-DD"
  );
  public selectedTerminationDate$ = new FilterDateObservable<SpaOverride>(
    "termination_date",
    "YYYY-MM-DD"
  );
  public selectedLDCCode$ = new FilterListObservable<EntityRef, SpaOverride>(
    "ldc_code",
    (i) => i.entity_code
  );
  public selectedELDCCode$ = new FilterListObservable<EntityRef, SpaOverride>(
    "eldc_code",
    (i) => i.entity_code
  );
  public selectedProfileClass$ = new FilterListObservable<
    EntityRef,
    SpaOverride
  >("profile_class_code", (i) => i.entity_code);
  public selectedRetailer$ = new FilterListObservable<EntityRef, SpaOverride>(
    "retailer_code",
    (i) => i.entity_code
  );
  public selectedSupplyClass$ = new FilterListObservable<
    EntityRef,
    SpaOverride
  >("retailer_code", (i) => i.entity_code);
  public selectedMeterType$ = new FilterListObservable<EntityRef, SpaOverride>(
    "meter_type_code",
    (i) => i.entity_code
  );
  public selectedLossClass$ = new FilterListObservable<EntityRef, SpaOverride>(
    "loss_class_code",
    (i) => i.entity_code
  );
  public selectedLocation$ = new FilterListObservable<EntityRef, SpaOverride>(
    "location_code",
    (i) => i.entity_code
  );
  public selectedCPNode$ = new FilterListObservable<EntityRef, SpaOverride>(
    "cpnode_code",
    (i) => i.entity_code
  );
  public selectedWeatherSensitiveFlag$ = new FilterListObservable<
    boolean,
    SpaOverride
  >("weather_sensitive_flag", (i) => i.toString());
  public selectedTieFlag$ = new FilterListObservable<boolean, SpaOverride>(
    "tie_flag",
    (i) => i.toString()
  );
  public selectedBMGFlag$ = new FilterListObservable<boolean, SpaOverride>(
    "bmg_flag",
    (i) => i.toString()
  );
  public selectedNetMeterRiderFlag$ = new FilterListObservable<
    boolean,
    SpaOverride
  >("net_meter_rider_flag", (i) => i.toString());
  public selectedUsageIngestionBlockFlag$ = new FilterListObservable<
    boolean,
    SpaOverride
  >("usage_ingestion_block_flag", (i) => i.toString());
  public selectedCreateTime$ = new FilterDateObservable<SpaOverride>(
    "create_time",
    "YYYY-MM-DD"
  );
  public selectedLastUpdateTime$ = new FilterDateObservable<SpaOverride>(
    "last_update_time",
    "YYYY-MM-DD"
  );
  public filters$ = combineLatest([
    this.selectedServicePointId$.params$,
    this.selectedEffectiveDate$.params$,
    this.selectedTerminationDate$.params$,
    this.selectedLDCCode$.params$,
    this.selectedELDCCode$.params$,
    this.selectedProfileClass$.params$,
    this.selectedRetailer$.params$,
    this.selectedSupplyClass$.params$,
    this.selectedMeterType$.params$,
    this.selectedLossClass$.params$,
    this.selectedLocation$.params$,
    this.selectedCPNode$.params$,
    this.selectedWeatherSensitiveFlag$.params$,
    this.selectedTieFlag$.params$,
    this.selectedBMGFlag$.params$,
    this.selectedNetMeterRiderFlag$.params$,
    this.selectedUsageIngestionBlockFlag$.params$,
    this.selectedCreateTime$.params$,
    this.selectedLastUpdateTime$.params$,
  ]).pipe(distinctUntilChanged(isEqual));

  // DATA
  public data$: Observable<APIEnvelope<SpaOverride>> = combineLatest([
    this.paginationParams$,
    this.filters$,
    this.sortOrder$.getOrder$(),
  ]).pipe(
    tap(() => {
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.spaOverrideService.getOverrides(page, filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = { error: true };
      const nodeData: IRowNode[] = [];
      this.gridApi?.forEachNode((node) => nodeData.push(node.data));
      this.gridApi?.applyTransaction({
        remove: nodeData,
      });
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<SpaOverride> = (data) =>
    data.data.service_point_local_def_uid;

  init() {
    this.subscription.add(
      this.pageNumber$.subscribe((pageNumber) => {
        this.pageNumber = pageNumber;
        this.pageNumberChange.emit(pageNumber);
      })
    );
    this.subscription.add(
      this.pageSize$.subscribe((pageSize) => {
        this.pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      })
    );
    this.subscription.add(
      this.sortOrder$.getOrder$().subscribe((sortOrder) => {
        this.sortOrder = sortOrder;
        this.sortOrderChange.emit(sortOrder);
      })
    );
    this.subscription.add(
      this.filters$.subscribe((filters) => {
        this.filtersChange.emit(filters);
      })
    );
    this.subscription.add(
      this.data$.subscribe((res) => {
        this.totalResults = res.total;
        const nodeData: unknown[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node.data));
          this.gridApi?.applyTransaction({
            remove: nodeData,
          });
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
      })
    );

    if (this.actions$) {
      this.subscription.add(
        this.actions$.subscribe((action) => {
          switch (action.type) {
            case "CREATE": {
              this.onCreateAction();
            }
          }
        })
      );
    }
  }

  destroy() {
    this.selectedEffectiveDate$.cleanup();
    this.selectedTerminationDate$.cleanup();
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.editableRow.cleanup();
    this.destroy();
  }

  removeEditNodes() {
    this.editableRow.setValue({});
    const nodesToUpdate: IRowNode[] = [];
    const nodesToRemove: IRowNode[] = [];
    this.gridApi?.forEachNode((node) => {
      if (node.data?.edit_mode) {
        (node.data?.service_point_local_def_uid ? nodesToUpdate : nodesToRemove).push(
          node
        );
      }
    });

    this.gridApi.applyTransaction({
      remove: nodesToRemove.map(n => ({...(n?.data ?? {}), edit_mode: false})),
      update: nodesToUpdate.map(n => ({...(n?.data ?? {}), edit_mode: false})),
    });
    this.gridApi.redrawRows({
      rowNodes: nodesToUpdate
    })
  }

  onCreateAction() {
    this.removeEditNodes();
    this.editableRow.setValue(
      {},
      this.removeEditNodes.bind(this),
      this.onCreateDryRun.bind(this)
    );
    this.gridApi.applyTransaction({
      addIndex: 0,
      add: [
        {
          edit_mode: true,
        },
      ],
    });
    this.gridApi.ensureIndexVisible(0);
  }

  onCloneAction(id: string) {
    this.removeEditNodes();
    const node = this.gridApi.getRowNode(id);
    if (!node) {
      return;
    }
    const data = {
      ...(node.data ?? {}),
      service_point_local_def_uid: undefined,
      service_point_uid: undefined,
      effective_date: undefined,
      termination_date: undefined,
      create_time: undefined,
      created_by: undefined,
      last_update_time: undefined,
      last_updated_by: undefined,
    };
    this.editableRow.setValue(
      data,
      this.removeEditNodes.bind(this),
      this.onCreateDryRun.bind(this)
    );
    this.gridApi.applyTransaction({
      addIndex: (node.rowIndex ?? 0) + 1,
      add: [{ ...data, edit_mode: true }],
    });
    this.gridApi.ensureIndexVisible((node.rowIndex ?? 0) + 1);
  }

  onCreateDryRun(data: Partial<SpaOverride>) {
    console.log(data);
  }

  onUpdateAction(id: string) {
    this.removeEditNodes();
    const node = this.gridApi.getRowNode(id);
    if (!node) {
      return;
    }
    this.editableRow.setValue(
      node.data,
      this.removeEditNodes.bind(this),
      this.onUpdateDryRun.bind(this)
    );
    const updatedNode = {
      ...node,
      data: {
        ...node.data,
        edit_mode: true,
      }
    }
    this.gridApi.applyTransaction({
      update: [
        updatedNode.data
      ],
    });
    this.gridApi.redrawRows({
      rowNodes: [node]
    });
  }

  onUpdateDryRun(data: Partial<SpaOverride>) {
    console.log(data);
  }

  editableRow = new EditableRow<SpaOverride>({}, (data) =>
    [
      // all required fields are present
      "service_point_uid",
      "effective_date",
      "termination_date",
      "ldc_code",
      "eldc_code",
      "profile_class_code",
      "retailer_code",
      "supply_class_code",
      "meter_type_code",
      "loss_class_code",
      "location_code",
      "cpnode_code",
      "weather_sensitive_flag",
      "bmg_flag",
      "tie_flag",
      "net_meter_rider_flag",
      "usage_ingestion_block_flag",
    ].every((prop) => data[prop as keyof typeof data] !== undefined)
  );
  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };
  public sharedColumnDef: ColDef = {
    filter: false,
    sortable: false,
    minWidth: 100,
    width: 150,
    headerComponent: TableHeaderComponent,
    headerComponentParams: {
      sortable: true,
      filterable: true,
      sortOrder$: this.sortOrder$,
      filters$: this.filters$,
    },
  };
  public columnDefs: ColDef[] = [
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "service_point_id",
        display: "Service Point",
        leftAlignFilter: true,
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "service_point_id",
          selectedValues$: this.selectedServicePointId$,
          allowPartial: true,
          fetchData: this.referenceDataService.servicePoints.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: ServicePointIdReference) =>
            data.service_point_id,
          toIdValue: (data: ServicePointIdReference) => data.service_point_id,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "service_point_id",
            values: search ? [{ value: search }] : [],
          }),
          searchToData: (search: string): ServicePointIdReference => ({
            service_point_id: search,
          }),
        },
      },
      field: "service_point_uid",
      cellRenderer: ServicePointCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "effective_date",
        display: "Effective Date",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedEffectiveDate$,
        },
      },
      field: "effective_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
        format: "YYYY-MM-dd",
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "termination_date",
        display: "Termination Date",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedTerminationDate$,
        },
      },
      field: "termination_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
        format: "YYYY-MM-dd",
      },
    },
    {
      ...this.sharedColumnDef,
      width: 125,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "ldc_code",
        display: "LDC",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "ldc_code_search",
          selectedValues$: this.selectedLDCCode$,
          fetchData: this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "ldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "LDC",
        editableRow: this.editableRow,
        fetchEntityRefs:
          this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
      },
    },
    {
      ...this.sharedColumnDef,
      width: 125,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "eldc_code",
        display: "ELDC",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "eldc_code_search",
          selectedValues$: this.selectedELDCCode$,
          fetchData: this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "eldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "ELDC",
        editableRow: this.editableRow,
        fetchEntityRefs:
          this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "profile_class_code",
        display: "Profile Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "profile_class_code_search",
          selectedValues$: this.selectedProfileClass$,
          fetchData: this.referenceDataService.profileClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "profile_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Profile Class",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.profileClasses.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "retailer_code",
        display: "Retailer",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "retailer_code_search",
          selectedValues$: this.selectedRetailer$,
          fetchData: this.referenceDataService.retailers.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "retailer_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Retailer",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.retailers.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "supply_class_code",
        display: "Supply Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "supply_class_code_search",
          selectedValues$: this.selectedSupplyClass$,
          fetchData: this.referenceDataService.supplyClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "supply_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Supply Class",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.supplyClasses.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "meter_type_code",
        display: "Meter Type",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "meter_type_code_search",
          selectedValues$: this.selectedMeterType$,
          fetchData: this.referenceDataService.meterTypes.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "meter_type_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Meter Type",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.meterTypes.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "loss_class_code",
        display: "Loss Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "loss_class_code_search",
          selectedValues$: this.selectedLossClass$,
          fetchData: this.referenceDataService.lossClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "loss_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Loss Class",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.lossClasses.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "location",
        display: "Location",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "location_code_search",
          selectedValues$: this.selectedLocation$,
          fetchData: this.referenceDataService.locations.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "location_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "Location",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.locations.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "cp_node",
        display: "CP Node",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "cp_node_code_search",
          selectedValues$: this.selectedCPNode$,
          fetchData: this.referenceDataService.cpNodes.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      field: "cpnode_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        entityLabel: "CP Node",
        editableRow: this.editableRow,
        fetchEntityRefs: this.referenceDataService.cpNodes.bind(
          this.referenceDataService
        ),
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "weather_sensitive_flag",
        display: "Weather Sensitive",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "weather_sensitive_search",
          multi: false,
          selectedValues$: this.selectedWeatherSensitiveFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      field: "weather_sensitive_flag",
      cellRenderer: FlagCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "bmg_flag",
        display: "BMG",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "bmg_search",
          multi: false,
          selectedValues$: this.selectedBMGFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      field: "bmg_flag",
      cellRenderer: FlagCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "tie_flag",
        display: "Tie",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "tie_search",
          multi: false,
          selectedValues$: this.selectedTieFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      field: "tie_flag",
      cellRenderer: FlagCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "net_meter_rider_flag",
        display: "Net Meter Rider",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "net_meter_rider_search",
          multi: false,
          selectedValues$: this.selectedNetMeterRiderFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      field: "net_meter_rider_flag",
      cellRenderer: FlagCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "usage_ingestion_block_flag",
        display: "Usage Ingestion Block",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "usage_ingestion_block_search",
          multi: false,
          selectedValues$: this.selectedUsageIngestionBlockFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      field: "usage_ingestion_block_flag",
      cellRenderer: FlagCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "create_time",
        display: "Created On",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedCreateTime$,
        },
      },
      field: "create_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
        format: "YYYY-MM-dd",
        editable: false,
      },
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "last_update_time",
        display: "Last Updated On",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedLastUpdateTime$,
        },
      },
      field: "last_update_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        editableRow: this.editableRow,
        format: "YYYY-MM-dd",
        editable: false,
      },
    },
    {
      ...this.sharedColumnDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      cellRenderer: ActionCellComponent<SpaOverride>,
      cellRendererParams: {
        editableRow: this.editableRow,
        actions: [
          {
            name: "Clone",
            onAction: ((d: SpaOverride) =>
              this.onCloneAction(d.service_point_local_def_uid)).bind(this),
          },
          {
            name: "Edit",
            onAction: ((d: SpaOverride) =>
              this.onUpdateAction(d.service_point_local_def_uid)).bind(this),
          },
        ],
      },
    },
  ];
}

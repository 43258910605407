import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'string',
    standalone: true
})

export class StringPipe implements PipeTransform {
    transform(value: any, defaultValue: string = ''): string {
        return `${value ?? defaultValue}`
    }
} 
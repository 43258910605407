import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  NEVER,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { Filters } from "./table.types";
import { MDMAJobStatus } from "../settlements/service/settlements.types";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { FilterDateObservable } from "src/app/shared/utilities/filter-date-observable";
import { FilterListObservable } from "src/app/shared/utilities/filter-list-observable";
import { EntityRef } from "src/app/shared/models/referenceData";
import { APIEnvelope } from "src/app/shared/models/api";
import { SettlementsService } from "../settlements/service/settlements.service";
import { TableActionCellComponent } from "./table-action-cell/table-action-cell.component";
import * as moment from "moment";
import { FilterParams } from "src/app/shared/utilities/http-params";
import { DateFilterComponent } from "./filters/select-filter/date-filter.component";
import { SelectFilterComponent } from "./filters/select-filter/select-filter.component";
import { TableHeaderComponent } from "./table-header/table-header.component";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import {
  SortOrder,
  TableSortObservable,
} from "src/app/shared/utilities/table-sort-observable";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { isEqual } from "lodash";

@Component({
  standalone: true,
  selector: "app-mdma-table-aggregations-table",
  imports: [
    AgGridAngular,
    TablePaginationComponent,
  ],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1.5rem;
    }
  `,
  template: `
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 625px"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressNoRowsOverlay]="true"
      [suppressPaginationPanel]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class MDMAAggregationsTableComponent implements OnDestroy {
  settlementsService = inject(SettlementsService);
  referenceDataService = inject(ReferenceDataService);

  @Output() public pageNumberChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();
  @Output() public sortOrderChange = new EventEmitter<SortOrder>();
  @Output() public filtersChange = new EventEmitter<Filters>();
  @Output() public create = new EventEmitter<MDMAJobStatus>();
  @Output() public view = new EventEmitter<MDMAJobStatus>();

  private subscription = new Subscription();
  public gridApi: GridApi;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageSize, pageNumber]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  // SORTING
  public sortOrder: SortOrder = [];
  public sortOrder$ = new TableSortObservable();

  // FILTERS
  public selectedOperationDate$ = new FilterDateObservable<MDMAJobStatus>(
    "operating_day",
    "YYYY-MM-DD"
  );
  public selectedSettlementTypes$ = new FilterListObservable<
    EntityRef,
    MDMAJobStatus
  >("settlement_type", (i) => i.entity_code);
  public selectedCreatedOn$ = new FilterDateObservable<MDMAJobStatus>(
    "start_time",
    "YYYY-MM-DD"
  );
  public selectedRunStatus$ = new FilterListObservable<
    EntityRef,
    MDMAJobStatus
  >("run_status", (i) => i.entity_code);
  public filters$ = combineLatest([
    this.selectedOperationDate$.params$,
    this.selectedSettlementTypes$.params$,
    this.selectedCreatedOn$.params$,
    this.selectedRunStatus$.params$,
  ]).pipe(distinctUntilChanged(isEqual));

  // DATA
  public data$: Observable<APIEnvelope<MDMAJobStatus>> = combineLatest([
    this.paginationParams$,
    this.filters$,
    this.sortOrder$.getOrder$(),
  ]).pipe(
    tap(() => {
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.settlementsService.getJobs(page, filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = {error: true};
      const nodeData: IRowNode[] = [];
      this.gridApi?.forEachNode((node) => nodeData.push(node.data));
      this.gridApi?.applyTransaction({
        remove: nodeData,
      });
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<MDMAJobStatus> = (data) => data.data.job_uid;

  init() {
    this.subscription.add(
      this.pageNumber$.subscribe((pageNumber) => {
        this.pageNumber = pageNumber;
        this.pageNumberChange.emit(pageNumber);
      })
    );
    this.subscription.add(
      this.pageSize$.subscribe((pageSize) => {
        this.pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      })
    );
    this.subscription.add(
      this.sortOrder$.getOrder$().subscribe((sortOrder) => {
        this.sortOrder = sortOrder;
        this.sortOrderChange.emit(sortOrder);
      })
    );
    this.subscription.add(
      this.filters$.subscribe((filters) => {
        this.filtersChange.emit(filters);
      })
    );
    this.subscription.add(
      this.data$.subscribe((res) => {
        this.totalResults = res.total;
        const nodeData: unknown[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.noRowsOverlayParams = {error: false};
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
  }

  destroy() {
    this.selectedOperationDate$.cleanup();
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = {error: false};
  public sharedColumnDef: ColDef = {
    filter: false,
    sortable: false,
    minWidth: 200,
    headerComponent: TableHeaderComponent,
    headerComponentParams: {
      sortable: true,
      filterable: true,
      sortOrder$: this.sortOrder$,
      filters$: this.filters$,
    },
  };
  public columnDefs: ColDef[] = [
    {
      flex: 1,
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "operation_date",
        display: "Operation Date",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedOperationDate$,
        },
      },
      cellRenderer: ({ data }: { data: MDMAJobStatus }) =>
        moment(data.operating_day).format("MM/DD/yyyy"),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "settlement_type",
        display: "Settlement Type",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "settlement_type_search",
          selectedValues$: this.selectedSettlementTypes$,
          fetchData: this.referenceDataService.settlementTypes.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: MDMAJobStatus }) => data.settlement_type,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "run_status",
        display: "Status",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "run_status_search",
          selectedValues$: this.selectedRunStatus$,
          fetchData: this.referenceDataService.runStatuses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: MDMAJobStatus }) =>
        `<span class="status-pill ${data.run_status}">${data.run_status}</span>`,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "created_on",
        display: "Created On",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedCreatedOn$,
        },
      },
      cellRenderer: ({ data }: { data: MDMAJobStatus }) =>
        moment(data.start_time).format("MM/DD/yyy hh:mm:ssA"),
    },
    {
      filter: false,
      sortable: false,
      width: 75,
      pinned: "right",
      resizable: false,
      cellRenderer: TableActionCellComponent,
      cellStyle: {
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRendererParams: {
        options: [
          {
            label: "View",
            action: (data: MDMAJobStatus) => this.view.emit(data),
          },
          {
            label: "Run Again",
            action: (data: MDMAJobStatus) => this.create.emit(data),
          },
        ],
      },
    },
  ];
}

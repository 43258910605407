import { Component } from "@angular/core";
import type { INoRowsOverlayAngularComp } from "ag-grid-angular";
import type { INoRowsOverlayParams } from "ag-grid-community";

type Params =  INoRowsOverlayParams<any, any> & {
    error: boolean
}

@Component({
  standalone: true,
  imports: [],
  selector: "app-no-data-overlay-component",
  styles: `
    p {
        margin: 2rem;
        font-size: 0.9rem;
        font-weight: normal;

        &.error {
            font-weight: bold;
            color: red;
        }
    }
  `,
  template: `
  @if(params?.error) {
    <p class="error">
        An error occurred fetching data! Please try again.
    </p>
  } @else {
    <p>
        No data to display! If you expected to see data check that your filters are correct.
    </p>
}`,
})
export class TableNoDataOverlayComponent implements INoRowsOverlayAngularComp {
  params?: Params;

  agInit(params: Params): void {
    this.refresh(params);
  }

  refresh(params: Params): void {
    this.params = params;
  }
}

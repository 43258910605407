import { AsyncPipe, TitleCasePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { StringPipe } from "src/app/shared/pipes/string.pipe";
import { EditableRow } from "src/app/shared/utilities/editable-row";

export type ParamsType<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  VALUE
> = ICellRendererParams<DATA & { edit_mode?: boolean }, VALUE> &
  PROPS & {
    editableRow?: EditableRow<DATA>;
  };

@Component({
  standalone: true,
  imports: [StringPipe, TitleCasePipe, AsyncPipe],
  template: `
    DO NOT USE
    <!-- SHOW EDITING MODE -->
    @if (canEdit) {
    <div class="value-container">
      <!-- START CELL SPECIFIC -->
      <button class="value" role="button" (click)="selectCellForEdit()">
        {{ currentValue | string | titlecase }}
      </button>
      <!-- END CELL SPECIFIC -->
    </div>
    <div #position class="position">
      @if(currentlySelected$ | async) {
      <div #panel class="panel">
        <!-- START CELL SPECIFIC -->

        <!-- END CELL SPECIFIC -->
      </div>
      }
    </div>
    }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    <!-- END CELL SPECIFIC -->
    }
  `,
  styles: `
        :host {
          position: relative;
        }

        .value-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0.5rem 0;

            button {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid black;
                line-height: calc(100% + 0.5rem);
                font-weight: 500;
                padding-left: 0;
            }
        }

        .position {
            position: absolute;
            bottom: 0.25rem;
            left: 0rem;
            width: 0;
            height: 0;
        }

        .panel {
            position: fixed;
            max-height: 10rem;
            width: 12rem;
            background-color: white;
            border: 1px solid black;
            border-radius: 0;
        }

        // START CELL SPECIFIC
        // END CELL SPECIFIC 
      `,
})
export abstract class CellComponent<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  FIELD extends keyof DATA,
  VALUE extends DATA[FIELD]
> implements ICellRendererAngularComp, OnDestroy
{
  params: ParamsType<DATA, PROPS, VALUE>;
  inEditMode: boolean;
  canEdit: boolean;
  currentlySelected$ = new BehaviorSubject(false);
  field: FIELD | undefined;

  get currentValue() {
    if (this.field && this.params.editableRow) {
      return this.params.editableRow.getValue(this.field);
    }
    return undefined;
  }

  elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.currentlySelected$.value &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.unselectCellForEdit();
    }
  }

  agInit(params: ParamsType<DATA, PROPS, VALUE>): void {
    this.refresh(params);

    this.init();
  }

  init() {}
  onOpen() {}
  onClose() {}

  refresh(params: ParamsType<DATA, PROPS, VALUE>) {
    this.destroy();
    this.params = params;
    this.field = this.params.colDef?.field as FIELD | undefined;
    this.inEditMode = !!(
      this.params.editableRow &&
      this.params.data?.edit_mode
    )
    this.canEdit = !!(
      this.field &&
      this.inEditMode
    );
    this.currentlySelected$.next(
      this.canEdit && this.params.editableRow!.currentCell === this.field
    );
    return true;
  }

  updateValue(key: FIELD, value: DATA[FIELD]) {
    this.params.editableRow?.updateValue(key, value);
  }

  selectCellForEdit() {
    this.params.editableRow?.startEdit(this.field);
    this.onOpen();
    this.refresh(this.params);
  }

  unselectCellForEdit() {
    this.params.editableRow?.startEdit();
    this.onClose();
    this.refresh(this.params);
  }

  ngOnDestroy(): void {
    this.destroy();
    this.onClose();
  }

  destroy(): void {}
}

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import {
  filter,
  FilterParams,
  httpParams,
  pagination,
  PaginationParams,
  sort,
  SortParams,
  withHttpParams,
} from "src/app/shared/utilities/http-params";

export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class ServicePointsService {
  constructor(private http: HttpClient) {}

  getServicePoints(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http
      .get<ServicePointResponse>("spagg_attributes", { params, 
        headers: {
          'NOLOADING': 'true'
        }
      })
      .pipe(catchError(this.handleError));
  }

  getServicePoint(
    pageNumber = 0,
    pageSize = 25,
    servicePointId: string,
    referenceDate?: string | null
  ): Observable<ServicePointResponse> {
    let start = pageNumber * pageSize;

    let params = new HttpParams();
    let header = new HttpHeaders({ Authorization: "ZXNzNGV2ZXI=" });

    params = params.set("start", start);
    params = params.set("limit", pageSize);
    params = params.set("service_point_source_id", `>${servicePointId}`);

    if (referenceDate) {
      params = params.set("effective_date", `<${referenceDate}`);
      params = params.set("termination_date", `>=${referenceDate}`);
    } else {
      params = params.set("effective_date", "<2024-04-10");
      params = params.set("termination_date", ">=2024-04-10");
    }

    const requestOptions = { params: params, headers: header };

    return this.http
      .get<ServicePointResponse>("spagg_attributes", requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error(
        "There is an issue with the client or network:",
        error.error
      );
    } else {
      console.error("Server-side error: ", error.error);
    }

    return throwError(
      () =>
        new Error(
          "Cannot retrieve service points from the server. Please try again."
        )
    );
  }
}

import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboarding-poc',
  templateUrl: './poc.component.html',
  styleUrl: './poc.component.scss'
})
export class DashboardPocComponent {
  constructor(
    private dateFormatter: DatePipe) {
  }
  ngOnInit() {

  }
}

import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  NEVER,
  Observable,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { PaginationParams } from "src/app/shared/utilities/http-params";
import { SpinnerComponent } from "../spinner/spinner.component";

@Component({
  standalone: true,
  selector: "app-select",
  imports: [FormsModule, CommonModule, MatIconModule, SpinnerComponent],
  styles: `
    .select-wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  
    .select-options {
      display: none;
      position: absolute;
    }

    .loading-spinner{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 9rem;
      width: 100%;

      app-spinner {width: 0;}
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-color: gray transparent;
      scrollbar-width: thin;
    }

    input:focus ~ .select-options,
    .select-options:hover {
      z-index: 1;
      display: flex;
      flex-direction: column;
      max-height: 10rem;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      background: white;

      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    label {
      position: absolute;
      top: 1rem;
      left: 1rem;
      opacity: 0.75;
      width: 0;
      white-space: nowrap;
    }

    input::placeholder {
      opacity: 0;
    }

    input:not(:placeholder-shown) + label {
      transform: scale(0.75) translateY(-1.25rem);
    }

    li > button {
      border: 0;
      width: 100%;
      background: white;

      &:hover, &.selected {
        background: lightgray;
      }
    }

    li > * {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      line-height: 1.25rem;
      text-align: left;
    }

    .paging-text {
      color: #2bb348;
      animation: pulse 2s linear infinite;;
    }

    .error {
      color: #e02020;
    }

    @keyframes pulse {
      from { opacity: 1; }
      50% { opacity: 0; }
      to { opacity: 1; }
    }
    `,
  template: `
    <div class="select-wrapper">
      <mat-icon class="search-icon" svgIcon="search"></mat-icon>
      <input
      #searchEl
        type="text"
        [id]="id"
        [placeholder]="'none'"
        [(ngModel)]="search"
        (keyup)="onSearch($event)"
        />
        <label [for]="id">{{label}}</label>
        <div
        class="select-options"
      >
        <ul (scroll)="onScroll($event)">
        @if(!!value) {
          <li>
            <button class="selected" (click)="onSelect($event, value)">
              {{ displayGetter(value) }}
            </button>
          </li>
        }
        @if(searching) {
          <li class="loading-spinner"><app-spinner/></li>
        } 
        @else if(hasError) {
          <li><span class="error">An unexpected error occured!</span><li>
        }
        @else { 
          @for (option of data; track valueGetter(option)) {
            <li>
              <button (click)="onSelect($event, option)">
                {{ displayGetter(option) }}
              </button>
            </li>
          } 
          @if (paging) {
            <li><span class="paging-text">...loading</span></li>
          } 
        }
        </ul>
      </div>
    </div>
  `,
})
export class SelectComponent<T, K> implements OnInit, OnDestroy {
  @Input({ required: true }) displayGetter: (data: T) => string;
  @Input({ required: true }) valueGetter: (data: T) => K;
  @Input({ required: true }) hasMoreOptions: (data: T[], pageSize: number) => boolean;
  @Input({ required: true }) fetchOptions: (
    search: string,
    page: PaginationParams
  ) => Observable<T[]>;
  @Input({ required: true }) value: T;
  @Input() pageSize: number = 25;
  @Input() id: string;
  @Input() label: string;

  @Output() valueChange = new EventEmitter<T>();

  @ViewChild("searchEl") searchEl: ElementRef;

  public search = "";
  public searching = false;
  public paging = false;
  public hasMore = false;
  public hasError = false;
  public data: T[] = [];

  private subscription = new Subscription();
  private pageTrigger$ = new BehaviorSubject(void 0);
  private search$: BehaviorSubject<string>;

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    if (this.hasMore && reachedThreshold) {
      this.pageTrigger$.next(void 0);
    }
  }

  onSearch(evt: Event) {
    this.search$.next((evt.target as any)?.value ?? "");
  }

  onSelect(evt: Event, item: T) {
    evt.stopPropagation();
    this.value = item;
    this.valueChange.emit(item);
    this.search$.next(this.displayGetter(item));
    this.searchEl.nativeElement.blur();
  }

  ngOnInit() {
    this.search = this.value ? this.displayGetter(this.value) : "";
    this.search$ = new BehaviorSubject(this.search);

    this.subscription.add(
      combineLatest([
        this.search$.pipe(
          tap((search) => {
            this.searching = true;
            this.search = search;
            this.data = [];
          })
        ),
        this.pageTrigger$.pipe(
          tap(() => {
            this.paging = true;
          }),
          map(() => Math.floor(this.data.length / this.pageSize))
        ),
      ])
        .pipe(
          switchMap(([search, pageNumber]) =>
            this.fetchOptions(search, { pageNumber, pageSize: this.pageSize })
          ),
          tap((data) => {
            this.hasMore = this.hasMoreOptions(data, this.pageSize);
            this.paging = false;
            this.searching = false;
            this.data = [...this.data, ...data];
          }),
          catchError((err) => {
            console.error(err);
            this.hasError = true;
            return NEVER;
          })
        )
        .subscribe(() => {
          this.hasError = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'maskedDate',
    pure: true,
    standalone: true
})

export class MaskedDate implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: Date, masks: {value: Date, name: string}[]): string | null {
        const foundMask = masks.find(m => moment(value).isSame(moment(m.value), 'D'));
        return foundMask?.name ?? this.datePipe.transform(value);
    }
} 
import { Component } from "@angular/core";
import { AsyncPipe, DatePipe } from "@angular/common";
import { CellComponent } from "./cell.component";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import * as moment from "moment";
import { DateStructPipe } from "src/app/shared/pipes/dateStruct.pipe";
import { ToDatePipe } from "src/app/shared/pipes/toDate.pipe";

type DateCellComponentProps = {
  format: string;
  editable: false;
};

@Component({
  standalone: true,
  imports: [AsyncPipe, DatePipe, NgbDatepicker, FormsModule, DateStructPipe, ToDatePipe],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  styles: `
        :host {
            position: relative;
        }

        .value-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0.5rem 0;

            button {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid black;
                line-height: calc(100% + 0.5rem);
                font-weight: 500;
                padding-left: 0;
            }
        }

        .position {
            position: absolute;
            bottom: 0.25rem;
            left: 0rem;
            width: 0;
            height: 0;
        }

        .panel {
            position: fixed;
            max-height: 10rem;
            width: 12rem;
            background-color: white;
            border: 1px solid black;
            border-radius: 0;
        }

        // START CELL SPECIFIC
        .panel {
          max-height: unset;
          width: 15rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .app-datepicker::ng-deep {
          border: none;
          border-radius: 0;
          padding: 0;
          font-size: 0.9rem;
          line-height: 1.25rem;

          .ngb-dp-header {
            background-color: transparent;

            .ngb-dp-arrow{
              display: none;
            }
          }

          .ngb-dp-months {
            display: block;
            width: 100%;

            .ngb-dp-week {
              justify-content: space-between;
              display: none;

              &:has(.ngb-dp-day:not(.hidden)) {
                display: flex;
              }
            }
          }

          .ngb-dp-day {
            font-weight: 500;
          }

          .ngb-dp-weekdays {
            display: flex !important; 
            background-color: transparent;
            border: none;

            .ngb-dp-weekday {
              color: black;
              font-weight: 700;
            }
          }

          .ngb-dp-month:first-child .ngb-dp-week {
            padding-left: 0; 
          }

          .ngb-dp-month:last-child .ngb-dp-week {
            padding-right:0; 
          }

          .ngb-dp-navigation-select {
            justify-content: space-between;
            gap: 1rem;
            width: 100%;

            select {
              width: 100%;
              padding: 0.5rem 1rem 0.25rem 1rem;
              position: relative;
              width: 100%;
              border: none;
              background: white;
              text-align: center;
              border-bottom: 1px solid black;
              border-radius: 0;
              -moz-appearance:none; /* Firefox */
              -webkit-appearance:none; /* Safari and Chrome */
              appearance:none;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }

          [ngbDatepickerDayView] {
            border-radius: 50%;
          }
          
          ngb-datepicker-month {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            margin-top: 0.75rem;
          }
        }
        // END CELL SPECIFIC 
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit) { @if(params.editable === false) { - } @else {

    <div class="value-container">
      <!-- START CELL SPECIFIC -->
      <button class="value" role="button" (click)="selectCellForEdit()">
        {{ currentValue | date:params.format }}
      </button>
      <!-- END CELL SPECIFIC -->
    </div>
    <div #position class="position">
      @if(currentlySelected$ | async) {
      <div #panel class="panel">
        <!-- START CELL SPECIFIC -->
        <ngb-datepicker
          #dp
          class="app-datepicker"
          [startDate]="currentValue | toDate | dateStruct"
          [ngModel]="currentValue | toDate"
          (ngModelChange)="setValue($event)"
          outsideDays="hidden"
        />
        <!-- END CELL SPECIFIC -->
      </div>
      }
    </div>
    } }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    {{ params.value | date:params.format }}
    <!-- END CELL SPECIFIC -->
    }
  `,
})
export class DateCellComponent<
  DATA extends Record<string, Date>
> extends CellComponent<DATA, DateCellComponentProps, keyof DATA, DATA[keyof DATA] & string> {
  setValue(val: Date) {
    if (this.field) {
      this.updateValue(
        this.field,
        moment(val).format(
          this.params.format.replace("dd", "DD")
        ) as DATA[keyof DATA] & string
      );
    }
  }
}

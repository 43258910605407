import { Component } from "@angular/core";
import { AsyncPipe } from "@angular/common";
import { CellComponent } from "./cell.component";
import { Observable, Subscription } from "rxjs";
import { EntityRef } from "src/app/shared/models/referenceData";
import {
  PaginationParams,
  FilterParams,
  SortParams,
} from "src/app/shared/utilities/http-params";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { SearchObservable } from "src/app/shared/utilities/search-observable";

type BasicEntityRefCellComponentProps = {
  entityLabel: string;
  fetchEntityRefs: (
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) => Observable<EntityRef[]>;
};

@Component({
  standalone: true,
  imports: [SpinnerComponent, FormsModule, MatIcon, AsyncPipe],
  styles: `
      :host {
          position: relative;
      }

      .value-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          padding: 0.5rem 0;

          button {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
              background-color: transparent;
              border: none;
              border-bottom: 1px solid black;
              line-height: calc(100% + 0.5rem);
              font-weight: 500;
              padding-left: 0;
          }
      }

      .position {
          position: absolute;
          bottom: 0.25rem;
          left: 0rem;
          width: 0;
          height: 0;
      }

      .panel {
          position: fixed;
          max-height: 10rem;
          width: 12rem;
          background-color: white;
          border: 1px solid black;
          border-radius: 0;
      }

      // START CELL SPECIFIC
      .panel {
        min-height: 9rem;
        max-height: 12rem;
      }

      .search-container {     
        position: relative;
        width: 100%;
        height: 3rem;
        padding: 0.5rem 0.75rem;

        & > * {
          height: 2rem;
        }

        input {
          width: 100%;
          padding: 0rem 1.75rem 0.25rem 0.25rem;
          border: none;
          border-bottom: 1px solid black;
          font-size: 0.9rem;
          line-height: 1.75rem;

          &:focus {
              outline: none;
          }
        }

        .search-icon {
          position: absolute;
          top: 0.5rem;
          right: 0.75rem;
          height: 2rem;
        }

        label {
          position: absolute;
          top: 0.5rem;
          left: 1rem;
          opacity: 0.75;
          width: 0;
          white-space: nowrap;
        }

        input::placeholder {
          opacity: 0;
        }

        input:not(:placeholder-shown) + label {
          transform: scale(0.75) translateY(-1.25rem);
        }
      }

      .spinner-container {
        padding: 3rem 0;
        position: absolute;
        left: 50%;
        top: 3rem;
      }

      .paging-text {
        margin-left: 1rem;
        font-weight: normal;
        color: #2bb348;
        animation: pulse 2s linear infinite;;
      }
      
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        max-height: 8rem;
        overflow-y: scroll;

        li {
          margin: 0;
          padding: 0;
        }
        
        li.error {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 0.5rem;
          color: #e02020;
          text-align: center;
          text-wrap: auto;
          white-space: normal;
          line-height: 1rem;
          font-weight: normal;

          p {
            margin: 0;
          }
        }

        button {
          padding: 0.5rem 1rem;
          margin: 0;
          width: 100%;
          text-align: start;
          line-height: 1.25rem;
          font-size: 0.9rem;
          background: white;
          border: none;

          &:hover {
            background: gainsboro;
          }
      }
  }
        // END CELL SPECIFIC 
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit) {
    <div class="value-container">
      <!-- START CELL SPECIFIC -->
      <button class="value" role="button" (click)="selectCellForEdit()">
        {{ currentValue }}
      </button>
      <!-- END CELL SPECIFIC -->
    </div>
    <div #position class="position">
      @if(currentlySelected$ | async) {
      <div #panel class="panel">
        <!-- START CELL SPECIFIC -->
        <!-- SHOW SEARCH -->
        <div class="search-container">
          <mat-icon class="search-icon" svgIcon="search"></mat-icon>
          <input
            type="text"
            [id]="'{{field}}' + '-edit-search-id'"
            [placeholder]="'none'"
            [ngModel]="search$.searchTerm$ | async"
            (keyup)="onSearch($event)"
          />
          <label [for]="'{{field}}' + '-edit-search-id'">{{
            params.entityLabel
          }}</label>
        </div>
        <!-- SHOW LOADING INDICATOR -->
        @if(search$.searching$ | async) {
        <div class="spinner-container">
          <app-spinner />
        </div>
        } @else {
        <ul>
          <!-- SHOW ENTITY REFS -->
          @for (result of results; track result.entity_code) {
          <li>
            <button (click)="setValue(result)">
              {{ result.entity_name }}
            </button>
          </li>
          }
          <!-- SHOW PAGING -->
          @if(search$.paging$ | async) {
          <li><span class="paging-text">...loading</span></li>
          }
          <!-- SHOW ERROR -->
          @if(search$.error$ | async) {
          <li class="error">
            <p>An error occurred while fetching data.</p>
            <p>Please try again.</p>
          </li>
          }
        </ul>
        }
        <!-- END CELL SPECIFIC -->
      </div>
      }
    </div>
    }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    {{ params.value }}
    <!-- END CELL SPECIFIC -->
    }
  `,
})
export class BasicEntityRefCellComponent<
  DATA extends Record<string, unknown>
> extends CellComponent<
  DATA,
  BasicEntityRefCellComponentProps,
  keyof DATA,
  DATA[keyof DATA] & string
> {
  private resultsSub = new Subscription();
  public results: EntityRef[];

  public search$ = new SearchObservable(
    ((searchTerm: string, pageNumber: number, pageSize: number) =>
      this.params.fetchEntityRefs(
        { pageNumber, pageSize },
        [{ name: "entity_name", values: [{ value: searchTerm }] }],
        [{ name: "entity_name" }]
      )).bind(this),
    1000
  );

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    const hasMore = this.search$.hasMore$.value;
    const notPaging = !this.search$.paging$.value;
    const notSearching = !this.search$.searching$.value;
    if (hasMore && reachedThreshold && notPaging && notSearching) {
      this.search$.page();
    }
  }

  onSearch(evt: KeyboardEvent) {
    this.search$.search((evt.target as any).value ?? "");
  }

  override onOpen() {
    super.onOpen();
    this.resultsSub.add(
      this.search$.results$.subscribe((results) => {
        this.results = results;
      })
    );
    this.search$.search("");
  }

  override onClose() {
    this.resultsSub.unsubscribe();
    this.results = [];
    super.onClose();
  }

  onSelect(evt: Event, item: EntityRef) {
    evt.stopPropagation();
    this.setValue(item);
    this.unselectCellForEdit();
  }

  setValue(item: EntityRef) {
    if (this.field) {
      this.updateValue(this.field, item.entity_code as DATA[keyof DATA] & string);
    }
  }
}

import { Component } from "@angular/core";
import { SpinnerComponent } from "../../spinner/spinner.component";
import type { ILoadingOverlayAngularComp } from "ag-grid-angular";
import type { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
    standalone: true,
    imports: [SpinnerComponent],
    selector: 'app-table-overlay-component',
    template: `<app-spinner/>`,
})
export class TableLoadingOverlayComponent implements ILoadingOverlayAngularComp {
    agInit(params: ILoadingOverlayParams<any, any>): void {
        this.refresh(params);
    }
    
    refresh(params: ILoadingOverlayParams<any, any>): void {
    
    }
}
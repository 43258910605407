import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicePoint } from 'src/app/shared/models/servicePoint';
import { ServicePointsService } from '../service-points/service-points.service';

@Component({
  selector: 'app-service-point-details',
  templateUrl: './service-point-details.component.html',
  styleUrl: './service-point-details.component.scss'
})
export class ServicePointDetailsComponent {
  @Input() servicePoint?: ServicePoint;
  @Input() referenceDate?: string;
  
  constructor(    
    private service: ServicePointsService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  profileSegment() {
    this.router.navigate(['/profile-segment'], { queryParams : { profile_class_code: this.servicePoint?.profile_class_code }  });
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingSpinnerService } from 'src/app/public/loading-spinner/service/loading-spinner.service';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {

  constructor(
    public spinnerService: LoadingSpinnerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const showLoading = !request.headers.has('NOLOADING');
    const newRequest = request.clone({
      headers: request.headers.delete('NOLOADING')
    });
    if(showLoading) {    
    this.spinnerService.handleRequest('plus');
    return next
    .handle(newRequest)
    .pipe(
        finalize(this.finalize.bind(this))
      );
    }
    return next.handle(newRequest);
  }

  finalize = (): void => this.spinnerService.handleRequest();

}

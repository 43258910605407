import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  selector: "app-quick-datepicker",
  imports: [FormsModule, CommonModule, MatIconModule, NgbDatepickerModule],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  styles: `

    .wrapper {
      position: relative;
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      border: none;
      background: white;
      text-align: left;
      font-size: 0.9rem;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border: 1px solid black;
      box-shadow: 0px 0px 1px #83e197;
      
      &:hover {
        box-shadow: 0px 0px 5px #83e197;
      }

      &:focus {
        outline: none;
      }
    }

    .date-popover {
      position: absolute;
      z-index: 5;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    .specific-dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      button {
        font-size: 0.9rem;
        line-height: 1.25rem;
        border-radius: 0.25rem;
        background-color: transparent;
        border: 1px solid black;
        color: black;
        padding: 0.5rem 0;

        &.selected {
          color: white;
          border-color: transparent;
          background-color: rgba(var(--bs-primary-rgb), 1);
          box-shadow: 1px 0px 5px lightgray;
        }
      }
    }

    .app-datepicker::ng-deep {
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 0.9rem;
      line-height: 1.25rem;

      .ngb-dp-header {
        background-color: transparent;

        .ngb-dp-arrow{
          display: none;
        }
      }

      .ngb-dp-months {
        display: block;
        width: 100%;

        .ngb-dp-week {
          justify-content: space-between;
          display: none;

          &:has(.ngb-dp-day:not(.hidden)) {
            display: flex;
          }
        }
      }

      .ngb-dp-weekdays {
        display: flex !important; 
        background-color: transparent;
        border: none;

        .ngb-dp-weekday {
          color: black;
          font-weight: 700;
        }
      }

      .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0; 
      }

      .ngb-dp-month:last-child .ngb-dp-week {
        padding-right:0; 
      }

      .ngb-dp-navigation-select {
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        select {
          width: 100%;
          padding: 0.5rem 1rem 0.25rem 1rem;
          position: relative;
          width: 100%;
          border: none;
          background: white;
          text-align: center;
          border-bottom: 1px solid black;
          border-radius: 0;
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          
        }
      }

      [ngbDatepickerDayView] {
        border-radius: 50%;
      }
      
      ngb-datepicker-month {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 0.75rem;
      }
    }
    `,
  template: `
    <button #btn class="action-btn" (click)="onClick($event)">
      {{label}}
      <mat-icon class="calendar-icon" svgIcon="calendar_month"></mat-icon>
      @if(open) {
        <div class="date-popover" #pop>
          @if(specificDates.length) {<div class="specific-dates">
          @for (specificDate of specificDates; track $index) {
            <button 
              (click)="onValueChange(specificDate.value)">
              {{specificDate.name}}
            </button>
          }
        </div>}
        <ngb-datepicker
          #dp
          class="app-datepicker"
          [ngModel]="{}"
          (ngModelChange)="onValueChange($event)"
          outsideDays="hidden"
        />
      </div>
    }
    </button>
  `,
})
export class QuickDatePickerComponent {
  @Input() label: string;
  @Input() specificDates: {value: Date, name: string}[] = []
  @Output() valueChange = new EventEmitter<Date>();

  @ViewChild('btn')
  public btn: ElementRef

  public elementRef: ElementRef = inject(ElementRef);

  open = false;

  onValueChange(newDate: Date) {
    this.open = false;
    this.valueChange.emit(newDate);
  }

  @HostListener("document:click", ["$event"])
  onClick(evt: MouseEvent) {
      this.open = this.elementRef.nativeElement.contains(evt.target as Node);
  };
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  withHttpParams,
  pagination,
  sort,
  filter,
} from "src/app/shared/utilities/http-params";
import { catchError, Observable, of } from "rxjs";
import { SpaOverrideResponse } from "src/app/shared/models/spaOverrideResponse";
import * as moment from "moment";

export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class SpaOverrideService {
  constructor(private http: HttpClient) {}

  getOverrides(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http.get<SpaOverrideResponse>("spa_overrides", {
      params,
      headers: {
        NOLOADING: "true",
      },
    }).pipe(catchError((err): Observable<SpaOverrideResponse> => {
      return of({// TODO remove
        results: new Array(page?.pageSize ?? 25).fill(0).map((_,i) => ({
          service_point_local_def_uid: `service_point_local_def_uid_${page?.pageNumber ?? 0}_${i}`,
          service_point_uid: `service_point_uid_${page?.pageNumber ?? 0}_${i}`,
          effective_date: moment().toISOString(),
          termination_date: moment().toISOString(),
          ldc_code: 'ldc_code',
          eldc_code: 'eldc_code',
          profile_class_code: 'profile_class_code',
          retailer_code: 'retailer_code',
          supply_class_code: 'supply_class_code',
          meter_type_code: 'meter_type_code',
          loss_class_code: 'loss_class_code',
          location_code: 'location_code',
          cpnode_code: 'cpnode_code',
          bmg_flag: true,
          tie_flag: true,
          weather_sensitive_flag: true,
          usage_ingestion_block_flag: true,
          net_meter_rider_flag: true,
          create_time: moment().toISOString(),
          created_by: 'q186994',
          last_update_time: moment().toISOString(),
          last_updated_by: 'q186994',
        })), 
        total: 50000
      })
    }));
  }
}

import { Component } from "@angular/core";
import { AsyncPipe, TitleCasePipe } from "@angular/common";
import { CellComponent } from "./cell.component";
import { StringPipe } from "src/app/shared/pipes/string.pipe";

@Component({
  standalone: true,
  imports: [TitleCasePipe, StringPipe, AsyncPipe],
  styles: `
        :host {
            position: relative;
        }

        .value-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0.5rem 0;

            button {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid black;
                line-height: calc(100% + 0.5rem);
                font-weight: 500;
                padding-left: 0;
            }
        }

        .position {
            position: absolute;
            bottom: 0.25rem;
            left: 0rem;
            width: 0;
            height: 0;
        }

        .panel {
            position: fixed;
            max-height: 10rem;
            width: 12rem;
            background-color: white;
            border: 1px solid black;
            border-radius: 0;
        }

        // START CELL SPECIFIC
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            li {
                padding: 0;
                margin: 0;
                width: 100%;

                button {
                    padding: 0.5rem 1rem;
                    margin: 0;
                    width: 100%;
                    text-align: start;
                    line-height: 1.25rem;
                    font-size: 0.9rem;
                    background: white;
                    border: none;

                    &:hover {
                        background: gainsboro;
                    }
                }
            }
        }
        // END CELL SPECIFIC 
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit) {
    <div class="value-container">
      <!-- START CELL SPECIFIC -->
      <button
        class="value"
        role="button"
        (click)="selectCellForEdit()"
      >
        {{ currentValue | string | titlecase }}
      </button>
      <!-- END CELL SPECIFIC -->
    </div>
    <div #position class="position">
      @if(currentlySelected$ | async) {
      <div #panel class="panel">
        <!-- START CELL SPECIFIC -->
        <ul>
          <li>
            <button (click)="setValue(true)">
              {{ true | string | titlecase }}
            </button>
          </li>
          <li>
            <button (click)="setValue(false)">
              {{ false | string | titlecase }}
            </button>
          </li>
        </ul>
        <!-- END CELL SPECIFIC -->
      </div>
      }
    </div>
    }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    {{ params.value | string | titlecase }}
    <!-- END CELL SPECIFIC -->
    }
  `,
})
export class FlagCellComponent<
  DATA extends Record<string, unknown>
> extends CellComponent<DATA, {}, keyof DATA, DATA[keyof DATA] & boolean> {

  setValue(val: boolean) {
    if (this.field) {
      this.updateValue(this.field, val as DATA[keyof DATA] & boolean);
    }
  }
}

import { map, scan, share, shareReplay, startWith, Subject } from "rxjs";

export type SortEvent = {
    name: string;
    multi: boolean;
};

export type SortDirection = 'asc' | 'desc';

export type SortOrder = {
    name: string;
    direction: SortDirection;
}[]

export type CurrentSort = {
    name: string;
    direction: SortDirection;
    order: number | null
}

export class TableSortObservable {
    private event$ = new Subject<SortEvent>();
    private sortOrder$ = this.event$.pipe(
        scan((acc, value): SortOrder => {
            const currentDirection: SortDirection | undefined = acc.find(s => s.name === value.name)?.direction;
            if(!currentDirection && !value.multi) {
                return [{
                    name: value.name,
                    direction: 'asc'
                }]
            }
            if(currentDirection === 'asc' && !value.multi) {
                return [{
                    name: value.name,
                    direction: 'desc'
                }]
            }
            if(currentDirection === 'desc' && !value.multi) {
                return []
            }
            if(!currentDirection && value.multi) {
                return [
                    ...acc, 
                    {
                        name: value.name,
                        direction: 'asc'
                    }
                ]
            }
            if(currentDirection === 'asc' && value.multi) {
                return acc.map(s => s.name === value.name 
                    ? {name: value.name, direction: 'desc'}
                    : s);
            }
            if(currentDirection === 'desc' && value.multi) {
                return acc.filter(s => s.name !== value.name);
            }
            else {
                return []
            }
        }, [] as SortOrder),
        startWith([] as SortOrder),
        shareReplay(1)
    );

    next(val: SortEvent) {
        this.event$.next(val);
    }

    getOrder$(){
        return this.sortOrder$;
    }

    getCurrentSort(name: string) {
        return this.sortOrder$.pipe(
            map(so => so
                .map((s: SortOrder[number], index: number, orig: SortOrder): CurrentSort => ({
                    ...s,
                    order: orig.length > 1 ? index + 1: null
                }))
                .find(s => s.name === name)
            )
        );
    }
}
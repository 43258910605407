import { Component } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-service-points',
  template: `
   <app-page-header title="Service Point Attributes">
    <app-quick-datepicker
      label="Operating Date"
      (valueChange)="onQuickDateSelect($event)"
    />
    </app-page-header>
    <app-page-content>
      <app-service-point-attributes-table
        [quickDates$]="quickDates$"
      />
    </app-page-content>
    `,
  styles: ``
})
export class ServicePointsComponent {
  quickDates$ = new Subject<Date>();

  onQuickDateSelect(val: Date) {
    this.quickDates$.next(val);
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { APIEnvelope } from "src/app/shared/models/api";
import {
  EntityRef,
  ServicePointIdReference,
} from "src/app/shared/models/referenceData";
import {
  FilterParams,
  httpParams,
  pagination,
  filter,
  PaginationParams,
  withHttpParams,
  sort,
  SortParams,
} from "src/app/shared/utilities/http-params";

@Injectable({
  providedIn: "root",
})
export class ReferenceDataService {
  constructor(private http: HttpClient) {}

  private get<T>(
    path: string,
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http
      .get<APIEnvelope<T>>(`reference/${path}`, {
        headers: {
          NOLOADING: "true",
        },
        params,
      })
      .pipe(map((res) => res.results));
  }

  private static readonly defaultEntitySort: SortParams[] = [
    { name: "entity_name", direction: "asc" },
  ];

  private static readonly defaultServiceIdSort: SortParams[] = [
    { name: "service_point_id", direction: "asc" },
  ];

  settlementTypes(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "settlement_type",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  locations(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "location",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  profileClasses(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "profile_class",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  cpNodes(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "cp_node",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  runStatuses(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "run_status",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  deliveryClasses(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "delivery_class",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  lossClasses(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "loss_class",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  meterTypes(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "meter_type",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  retailers(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "retailer",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  supplyClasses(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "supply_class",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  lossDistributionCompanies(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<EntityRef>(
      "local_distribution_company",
      page,
      filters,
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  servicePoints(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    return this.get<ServicePointIdReference>(
      "service_point",
      page,
      filters,
      order ?? ReferenceDataService.defaultServiceIdSort
    );
  }
}

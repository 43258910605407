import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MaskedDate } from "src/app/shared/pipes/maskedDate.pipe";

@Component({
  standalone: true,
  selector: "app-datepicker",
  imports: [FormsModule, CommonModule, MatIconModule, NgbDatepickerModule, MaskedDate],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  styles: `
    .wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      background: white;
      text-align: left;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }

    .calendar-icon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  
    .date-popover {
      display: none;
      position: absolute;
    }

    input:focus ~ .date-popover,
    .date-popover:hover {
      z-index: 1;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    label {
      position: absolute;
      top: 1rem;
      left: 1rem;
      opacity: 0.75;
      width: 0;
      white-space: nowrap;
    }

    input::placeholder {
      opacity: 0;
    }

    input:not(:placeholder-shown) + label {
      transform: scale(0.75) translateY(-1.25rem);
    }

    .specific-dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      button {
        font-size: 0.9rem;
        line-height: 1.25rem;
        border-radius: 0.25rem;
        background-color: transparent;
        border: 1px solid black;
        color: black;
        padding: 0.5rem 0;

        &.selected {
          color: white;
          border-color: transparent;
          background-color: rgba(var(--bs-primary-rgb), 1);
          box-shadow: 1px 0px 5px lightgray;
        }
      }
    }

    .app-datepicker::ng-deep {
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 0.9rem;
      line-height: 1.25rem;

      .ngb-dp-header {
        background-color: transparent;

        .ngb-dp-arrow{
          display: none;
        }
      }

      .ngb-dp-months {
        display: block;
        width: 100%;

        .ngb-dp-week {
          justify-content: space-between;
          display: none;

          &:has(.ngb-dp-day:not(.hidden)) {
            display: flex;
          }
        }
      }

      .ngb-dp-weekdays {
        display: flex !important; 
        background-color: transparent;
        border: none;

        .ngb-dp-weekday {
          color: black;
          font-weight: 700;
        }
      }

      .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0; 
      }

      .ngb-dp-month:last-child .ngb-dp-week {
        padding-right:0; 
      }

      .ngb-dp-navigation-select {
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        select {
          width: 100%;
          padding: 0.5rem 1rem 0.25rem 1rem;
          position: relative;
          width: 100%;
          border: none;
          background: white;
          text-align: center;
          border-bottom: 1px solid black;
          border-radius: 0;
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          
        }
      }

      [ngbDatepickerDayView] {
        border-radius: 50%;
      }
      
      ngb-datepicker-month {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 0.75rem;
      }
    }
    `,
  template: `
    <div class="wrapper">
      <mat-icon class="calendar-icon" svgIcon="calendar_month"></mat-icon>
      <input
        type="button"
        [id]="id"
        [placeholder]="'none'"
        [value]="value | maskedDate:specificDates"
      />
      <label [for]="id">{{ label }}</label>
      <div class="date-popover">
        @if(specificDates.length) {<div class="specific-dates">
          @for (specificDate of specificDates; track $index) {
            <button 
              (click)="onValueChange(specificDate.value)" 
              [class.selected]="valueIs(specificDate.value)">
              {{specificDate.name}}
            </button>
          }
        </div>}
        <ngb-datepicker
          #dp
          class="app-datepicker"
          [ngModel]="value"
          (ngModelChange)="onValueChange($event)"
          outsideDays="hidden"
        />
      </div>
    </div>
  `,
})
export class DatePickerComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() specificDates: {value: Date, name: string}[] = []
  @Input() value: Date

  @Output() valueChange = new EventEmitter<Date>();

  onValueChange(newDate: Date) {
    this.value = newDate;
    this.valueChange.emit(newDate);
  }

  valueIs(date: Date) {
   return moment(date).isSame(moment(this.value), 'D')
  }
}

import { Injectable } from "@angular/core"

type Toast = {
    message: string,
    type: 'success' | 'error'
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toasts: Toast[] = [];

    show(toast: Toast) {
      this.toasts.push(toast);
    }

    remove(toast: Toast) {
        this.toasts = this.toasts.filter(t => t != toast);
      }
}
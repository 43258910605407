import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/public/user/service/user.service';


@Injectable({
    providedIn: 'root'
})
export class AuthResolver {
    constructor(private userService: UserService) { }

    public resolve(): Observable<any> {
        return this.userService.getUser();
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: 'toDate',
    pure: true,
    standalone: true
})

export class ToDatePipe implements PipeTransform {
    transform(value: Date | string | null | undefined): Date | undefined {
        return (value ? moment(value).toDate() : undefined);
    }
} 
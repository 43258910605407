import { Component } from "@angular/core";
import { SpaOverrideAction } from "./spah-override.types";
import { Subject } from "rxjs";

@Component({
  selector: "app-spah-overrides",
  template: `
    <app-page-header title="Service Point Overrides">
    <button class="action-btn" (click)="onCreate()">
        Create <mat-icon svgIcon="add_circle" />
      </button>
    </app-page-header>
    <app-page-content>
      <app-spah-overrides-table [actions$]="actions$"/>
    </app-page-content>
  `,
  styles: ``,
})
export class SpahOverridesComponent {
  actions$ = new Subject<SpaOverrideAction>()

  onCreate() {
    this.actions$.next({
      type: 'CREATE'
    });
  }
}

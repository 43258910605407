import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, NEVER, Observable, of, scan, shareReplay, startWith, Subject, switchMap, tap } from "rxjs";

export class SearchObservable<T> {
    private pageTrigger$ = new Subject<void>();

    public error$ = new BehaviorSubject(false);
    public searching$ = new BehaviorSubject(false);
    public searchTerm$ = new BehaviorSubject('');
    public paging$ = new BehaviorSubject(false);
    public hasMore$ = new BehaviorSubject(true);

    public results$ = this.searchTerm$.pipe(
        distinctUntilChanged(),
        tap(() => {
            this.searching$.next(true);
            this.paging$.next(false);
            this.error$.next(false);
            this.hasMore$.next(true);
        }),
        switchMap((searchTerm) => combineLatest([
            of(searchTerm),
            this.pageTrigger$.pipe(
                tap(() => {
                    this.paging$.next(true);
                }),
                scan((pageNumber) =>  pageNumber + 1, 0),
                startWith(0),
                distinctUntilChanged(),
                shareReplay(1),
            )
        ])),
        distinctUntilChanged(),
        switchMap(([searchTerm,  pageNumber]) => 
            this.fetch(searchTerm, pageNumber, this.pageSize)
                .pipe(
                    catchError((err) => {
                        console.error(err);
                        this.searching$.next(false);
                        this.paging$.next(false);
                        this.error$.next(true);
                        return NEVER;
                    })
                )
        ),
        tap((results) => {
            this.searching$.next(false);
            this.paging$.next(false);
            this.error$.next(false);
            this.hasMore$.next(results.length >= this.pageSize);
        }),
        shareReplay(1),
    );


    constructor(
        private readonly fetch: (searchTerm: string, pageNumber: number, pageSize: number) => Observable<T[]>, 
        private readonly pageSize: number = 1000) {
    }

    page() {
        this.pageTrigger$.next(void 0);
    }

    search(searchTerm: string) {
        this.searchTerm$.next(searchTerm);
    }
}
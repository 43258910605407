import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  NEVER,
  Observable,
  of,
  shareReplay,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { Filters } from "./table.types";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { APIEnvelope } from "src/app/shared/models/api";
import * as moment from "moment";
import { TableHeaderComponent } from "./table-header/table-header.component";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { ServicePointsService } from "../service-points/service-points.service";
import { ServicePoint } from "src/app/shared/models/servicePoint";
import { DateFilterComponent } from "./filters/select-filter/date-filter.component";
import { FilterDateObservable } from "src/app/shared/utilities/filter-date-observable";
import { EntityRef, ServicePointIdReference } from "src/app/shared/models/referenceData";
import { FilterListObservable } from "src/app/shared/utilities/filter-list-observable";
import { SelectFilterComponent } from "./filters/select-filter/select-filter.component";
import { FilterParams } from "src/app/shared/utilities/http-params";
import { titlecase } from "src/app/shared/utilities/titlecase";
import {
  SortOrder,
  TableSortObservable,
} from "src/app/shared/utilities/table-sort-observable";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { isEqual } from "lodash";

@Component({
  standalone: true,
  selector: "app-service-point-attributes-table",
  imports: [
    AgGridAngular,
    TablePaginationComponent,
  ],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1.5rem;
    }
  `,
  template: `
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 625px"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class ServicePointAttributesTableComponent implements OnDestroy {
  servicePointsService = inject(ServicePointsService);
  referenceDataService = inject(ReferenceDataService);

  @Input() public quickDates$: Observable<Date>;

  @Output() public pageNumberChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();
  @Output() public sortOrderChange = new EventEmitter<SortOrder>();
  @Output() public filtersChange = new EventEmitter<Filters>();

  private subscription = new Subscription();
  public gridApi: GridApi;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageNumber, pageSize]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  // SORTING
  public sortOrder: SortOrder = [];
  public sortOrder$ = new TableSortObservable();

  // FILTERS
  public selectedServicePointId$ = new FilterListObservable<ServicePointIdReference, ServicePoint>(
    "service_point_id",
    (i) => i.service_point_id
  );
  public selectedEffectiveDate$ = new FilterDateObservable<ServicePoint>(
    "effective_date",
    "YYYY-MM-DD"
  );
  public selectedTerminationDate$ = new FilterDateObservable<ServicePoint>(
    "termination_date",
    "YYYY-MM-DD"
  );
  public selectedLDCCode$ = new FilterListObservable<EntityRef, ServicePoint>(
    "ldc_code",
    (i) => i.entity_code
  );
  public selectedELDCCode$ = new FilterListObservable<EntityRef, ServicePoint>(
    "eldc_code",
    (i) => i.entity_code
  );
  public selectedProfileClass$ = new FilterListObservable<
    EntityRef,
    ServicePoint
  >("profile_class_code", (i) => i.entity_code);
  public selectedRetailer$ = new FilterListObservable<EntityRef, ServicePoint>(
    "retailer_code",
    (i) => i.entity_code
  );
  public selectedSupplyClass$ = new FilterListObservable<
    EntityRef,
    ServicePoint
  >("retailer_code", (i) => i.entity_code);
  public selectedMeterType$ = new FilterListObservable<EntityRef, ServicePoint>(
    "meter_type_code",
    (i) => i.entity_code
  );
  public selectedLossClass$ = new FilterListObservable<EntityRef, ServicePoint>(
    "loss_class_code",
    (i) => i.entity_code
  );
  public selectedLocation$ = new FilterListObservable<EntityRef, ServicePoint>(
    "location_code",
    (i) => i.entity_code
  );
  public selectedCPNode$ = new FilterListObservable<EntityRef, ServicePoint>(
    "cpnode_code",
    (i) => i.entity_code
  );
  public selectedWeatherSensitiveFlag$ = new FilterListObservable<
    boolean,
    ServicePoint
  >("weather_sensitive_flag", (i) => i.toString());
  public selectedTieFlag$ = new FilterListObservable<boolean, ServicePoint>(
    "tie_flag",
    (i) => i.toString()
  );
  public selectedBMGFlag$ = new FilterListObservable<boolean, ServicePoint>(
    "bmg_flag",
    (i) => i.toString()
  );
  public selectedNetMeterRiderFlag$ = new FilterListObservable<
    boolean,
    ServicePoint
  >("net_meter_rider_flag", (i) => i.toString());
  public selectedUsageIngestionBlockFlag$ = new FilterListObservable<
    boolean,
    ServicePoint
  >("usage_ingestion_block_flag", (i) => i.toString());
  public selectedSPAOverrideFlag$ = new FilterListObservable<
    boolean,
    ServicePoint
  >("spa_override_flag", (i) => i.toString());
  public filters$ = combineLatest([
    this.selectedServicePointId$.params$,
    this.selectedEffectiveDate$.params$,
    this.selectedTerminationDate$.params$,
    this.selectedLDCCode$.params$,
    this.selectedELDCCode$.params$,
    this.selectedProfileClass$.params$,
    this.selectedRetailer$.params$,
    this.selectedSupplyClass$.params$,
    this.selectedMeterType$.params$,
    this.selectedLossClass$.params$,
    this.selectedLocation$.params$,
    this.selectedCPNode$.params$,
    this.selectedWeatherSensitiveFlag$.params$,
    this.selectedTieFlag$.params$,
    this.selectedBMGFlag$.params$,
    this.selectedNetMeterRiderFlag$.params$,
    this.selectedUsageIngestionBlockFlag$.params$,
    this.selectedSPAOverrideFlag$.params$,
  ]).pipe(distinctUntilChanged(isEqual));

  // DATA
  public data$: Observable<APIEnvelope<ServicePoint>> = combineLatest([
    this.paginationParams$,
    this.filters$,
    this.sortOrder$.getOrder$(),
  ]).pipe(
    tap(() => {
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.servicePointsService.getServicePoints(
        page,
        filters,
        sortOrder
      );
    }),
    catchError(() => {
      this.noRowsOverlayParams = {error: true};
      const nodeData: IRowNode[] = [];
      this.gridApi?.forEachNode((node) => nodeData.push(node.data));
      this.gridApi?.applyTransaction({
        remove: nodeData,
      });
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<ServicePoint> = (data) => data.data.service_point_uid + data.data.effective_date;

  init() {
    this.subscription.add(
      this.pageNumber$.subscribe((pageNumber) => {
        this.pageNumber = pageNumber;
        this.pageNumberChange.emit(pageNumber);
      })
    );
    this.subscription.add(
      this.pageSize$.subscribe((pageSize) => {
        this.pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      })
    );
    this.subscription.add(
      this.sortOrder$.getOrder$().subscribe((sortOrder) => {
        this.sortOrder = sortOrder;
        this.sortOrderChange.emit(sortOrder);
      })
    );
    this.subscription.add(
      this.filters$.subscribe((filters) => {
        this.filtersChange.emit(filters);
      })
    );
    if(this.quickDates$) {
      this.quickDates$.subscribe(date => {
        this.selectedEffectiveDate$.clearOp();
        this.selectedEffectiveDate$.setDate(date);
        this.selectedEffectiveDate$.setOp("<");
        this.selectedEffectiveDate$.setOp("==");
        this.selectedTerminationDate$.clearOp();
        this.selectedTerminationDate$.setDate(date);
        this.selectedTerminationDate$.setOp(">");
      });
    }
    this.subscription.add(
      this.data$.subscribe((res) => {
        this.totalResults = res.total;
        const nodeData: unknown[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node.data));
          this.gridApi?.applyTransaction({
            remove: nodeData,
          });
          this.noRowsOverlayParams = {error: false};
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
  }

  destroy() {
    this.selectedEffectiveDate$.cleanup();
    this.selectedTerminationDate$.cleanup();
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = {error: false};
  public sharedColumnDef: ColDef = {
    filter: false,
    sortable: false,
    minWidth: 100,
    width: 150,
    headerComponent: TableHeaderComponent,
    headerComponentParams: {
      sortable: true,
      filterable: true,
      sortOrder$: this.sortOrder$,
      filters$: this.filters$,
    },
  };
  public columnDefs: ColDef[] = [
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "service_point_id",
        display: "Service Point",
        leftAlignFilter: true,
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "service_point_id",
          selectedValues$: this.selectedServicePointId$,
          allowPartial: true,
          fetchData: this.referenceDataService.servicePoints.bind(
            this.referenceDataService
          ),          
          toDisplayValue: (data: ServicePointIdReference) => data.service_point_id,
          toIdValue: (data: ServicePointIdReference) => data.service_point_id,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "service_point_id",
            values: search ? [{ value: search }] : [],
          }),
          searchToData: (search: string):ServicePointIdReference => ({service_point_id: search}) 
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.service_point_id,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "effective_date",
        display: "Effective Date",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedEffectiveDate$,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        moment(data.effective_date).format("YYYY/MM/DD"),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "termination_date",
        display: "Termination Date",
        filterComponent: DateFilterComponent,
        filterComponentParams: {
          selectedValues$: this.selectedTerminationDate$,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        moment(data.termination_date).format("YYYY/MM/DD"),
    },
    {
      ...this.sharedColumnDef,
      width: 125,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "ldc_code",
        display: "LDC",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "ldc_code_search",
          selectedValues$: this.selectedLDCCode$,
          fetchData: this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.ldc_code,
    },
    {
      ...this.sharedColumnDef,
      width: 125,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "eldc_code",
        display: "ELDC",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "eldc_code_search",
          selectedValues$: this.selectedELDCCode$,
          fetchData: this.referenceDataService.lossDistributionCompanies.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.eldc_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "profile_class_code",
        display: "Profile Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "profile_class_code_search",
          selectedValues$: this.selectedProfileClass$,
          fetchData: this.referenceDataService.profileClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        data.profile_class_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "retailer_code",
        display: "Retailer",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "retailer_code_search",
          selectedValues$: this.selectedRetailer$,
          fetchData: this.referenceDataService.retailers.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.retailer_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "supply_class_code",
        display: "Supply Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "supply_class_code_search",
          selectedValues$: this.selectedSupplyClass$,
          fetchData: this.referenceDataService.supplyClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        data.supply_class_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "meter_type_code",
        display: "Meter Type",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "meter_type_code_search",
          selectedValues$: this.selectedMeterType$,
          fetchData: this.referenceDataService.meterTypes.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.meter_type_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "loss_class_code",
        display: "Loss Class",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "loss_class_code_search",
          selectedValues$: this.selectedLossClass$,
          fetchData: this.referenceDataService.lossClasses.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.loss_class_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "location",
        display: "Location",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "location_code_search",
          selectedValues$: this.selectedLocation$,
          fetchData: this.referenceDataService.locations.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.location_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "cp_node",
        display: "CP Node",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "cp_node_code_search",
          selectedValues$: this.selectedCPNode$,
          fetchData: this.referenceDataService.cpNodes.bind(
            this.referenceDataService
          ),
          toDisplayValue: (data: EntityRef) => data.entity_name,
          toIdValue: (data: EntityRef) => data.entity_code,
          toFilterParams: (search: string | null): FilterParams => ({
            name: "entity_name",
            values: search ? [{ value: search }] : [],
          }),
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) => data.cpnode_code,
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "weather_sensitive_flag",
        display: "Weather Sensitive",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "weather_sensitive_search",
          multi: false,
          selectedValues$: this.selectedWeatherSensitiveFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.weather_sensitive_flag.toString()),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "bmg_flag",
        display: "BMG",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "bmg_search",
          multi: false,
          selectedValues$: this.selectedBMGFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.bmg_flag.toString()),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "tie_flag",
        display: "Tie",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "tie_search",
          multi: false,
          selectedValues$: this.selectedTieFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.tie_flag.toString()),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "net_meter_rider_flag",
        display: "Net Meter Rider",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "net_meter_rider_search",
          multi: false,
          selectedValues$: this.selectedNetMeterRiderFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.net_meter_rider_flag.toString()),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "usage_ingestion_block_flag",
        display: "Usage Ingestion Block",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "usage_ingestion_block_search",
          multi: false,
          selectedValues$: this.selectedUsageIngestionBlockFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.usage_ingestion_block_flag.toString()),
    },
    {
      ...this.sharedColumnDef,
      headerComponentParams: {
        ...this.sharedColumnDef.headerComponentParams,
        name: "spa_override_flag",
        display: "SPA Override",
        filterComponent: SelectFilterComponent,
        filterComponentParams: {
          id: "spa_override_search",
          multi: false,
          selectedValues$: this.selectedSPAOverrideFlag$,
          fetchData: () => of([true, false]),
          toDisplayValue: (data: boolean) => titlecase(data.toString()),
          toIdValue: (data: boolean) => data,
        },
      },
      cellRenderer: ({ data }: { data: ServicePoint }) =>
        titlecase(data.spa_override_flag.toString()),
    },
  ];
}

import { Component } from "@angular/core";
import * as moment from "moment";
import { delay, of } from "rxjs";
import { PaginationParams } from "src/app/shared/utilities/http-params";

type Data = {
  id: string;
  name: string;
};

@Component({
  selector: "app-home",
  template: `
    <div class="app__container app__inner__container">
      <div class="container">
      </div>
    </div>
  `,
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {

  constructor() {}
}

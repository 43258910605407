import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { BehaviorSubject, combineLatest, map, shareReplay, Subscription, tap } from "rxjs";

@Component({
  selector: "app-table-pagination",
  imports: [MatIcon, CommonModule, FormsModule],
  standalone: true,
  template: `
    <button
      (click)="pageNumber$.next(0)"
      [disabled]="pageNumber === 0"
      class="page-arrow"
    >
      <mat-icon svgIcon="first-page" />
    </button>
    @for (quickPage of quickPages$ | async; track $index) {
    <button
      (click)="pageNumber$.next(quickPage)"
      class="quick-page"
      [class.current-page]="quickPage === pageNumber"
    >
      {{ quickPage + 1 }}
    </button>
    }
    <button
      (click)="pageNumber$.next(maxPageNumber)"
      [disabled]="pageNumber === maxPageNumber"
      class="page-arrow"
    >
      <mat-icon svgIcon="last-page" />
    </button>
    <hr/>
    <!-- <select placeholder="Page Size" [(ngModel)]="pageSize">
        @for(size of availablePageSizes; track size) {
            <option [value]="size">
              {{ size }} per page
            </option>
        }
    </select> -->
    <span>{{this.totalResults}} Results Available<span>
  `,
  styles: `
  :host {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    button {
        height: 1.5rem;
        min-width: 1.5rem;
        border: none;
        background: whitesmoke;
        border-radius: 0.25rem;
        color: black;

        &:hover {
            background: lightgray;
        }

        &.page-arrow {
            padding: 0;
        }
        
        &.quick-page {
            &.current-page {
                background: rgba(var(--bs-primary-rgb), 1);
                color: white;

                &:hover {
                    background: rgba(var(--bs-primary-rgb), 0.75);
                }
            }
        }
    }

    hr {
        transform:rotate(90deg);
        width: 1.5rem;
        margin: 0;
        opacity: 1;
    }

    select {
        height: 1.5rem;
        border-radius: 0.25rem;
    }
  }`,
})
export class TablePaginationComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private _pageNumber = 0;
  private _pageSize = 1000;
  private _totalResults = 0;
  
  public maxPageNumber = 0;
  public pageNumber$ = new BehaviorSubject(this._pageNumber);
  public pageSize$ = new BehaviorSubject(this._pageSize);
  public totalResults$ = new BehaviorSubject(this._totalResults);
  public maxPageNumber$ = combineLatest([this.pageSize$, this.totalResults$])
    .pipe(
      map(([ps, tr]) => Math.ceil(tr / ps) - 1) // 0 indexed
    )

  public quickPages$ = combineLatest([
    this.pageNumber$,
    this.maxPageNumber$,
  ]).pipe(
    map(([pn, mpn]) => {
      return new Array(5)
        .fill(Math.max(0, pn - 2))
        .map((n, i) => n + i)
        .filter((n) => n <= mpn);
    })
  );

  @Input() availablePageSizes = [1000];

  @Input()
  set pageNumber(val: number) {
    this.pageNumber$.next(parseInt(`${val}`, 10));
  }
  get pageNumber() {
    return this._pageNumber;
  }

  @Input()
  set pageSize(val: number) {
    this.pageSize$.next(parseInt(`${val}`, 10));
  }
  get pageSize() {
    return this._pageSize;
  }

  @Input()
  set totalResults(val: number) {
    this.totalResults$.next(val);
  }
  get totalResults() {
    return this._totalResults;
  }

  @Output() pageNumberChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.subscription.add(
      this.pageNumber$.subscribe((pageNumber) => {
        this._pageNumber = pageNumber;
        this.pageNumberChange.emit(pageNumber);
      })
    );
    this.subscription.add(
      this.pageSize$.subscribe((pageSize) => {
        this._pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      })
    );
    this.subscription.add(
      this.maxPageNumber$.subscribe((maxPageNumber) => {
        this.maxPageNumber = maxPageNumber;
      })
    );
    this.subscription.add(
      this.totalResults$.subscribe(totalResults => {
        this._totalResults= totalResults
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

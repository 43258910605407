import { filter, map, Observable, scan, shareReplay, startWith, Subject, Subscription } from "rxjs";
import { FilterParams } from "./http-params";
import * as moment from "moment";

export type DateFilterOperator = '==' | '>' | '<' | '>=' | '<=';

type DateFilterAction = {
    type: 'SET_OP',
    operator: Exclude<DateFilterOperator, '<=' | '>='>
} | {
    type: 'SET_DATE'
    date: Date | null
} | {
    type: "CLEAR_OP"
}

export type DateFilterState = {
    date: Date | null,
    operator: DateFilterOperator | null,
}

const initialState: DateFilterState = {
    date: null,
    operator: '=='
};

const rectifyOps = (current: DateFilterOperator | null, next: DateFilterOperator) => 
    ([
        [() => current === next, () => null],
        [() => current === '<=' && next === '<', () => '=='],
        [() => current === '<=' && next === '==', () => '<'],
        [() => current === '<' && next === '==', () => '<='],
        [() => current === '==' && next === '<', () => '<='],
        [() => current === '>=' && next === '>', () => '=='],
        [() => current === '>=' && next === '==', () => '>'],
        [() => current === '>' && next === '==', () => '>='],
        [() => current === '==' && next === '>', () => '>='],
        [() => true, () => next]
    ] as [() => boolean, () => DateFilterOperator | null][]
    ).find(([cond]) => cond())?.[1]() ?? null;

export class FilterDateObservable<T extends Record<string, any> = any> {
  private subscription = new Subscription();
  private actions$ = new Subject<DateFilterAction>();
  public state$: Observable<DateFilterState> = this.actions$.pipe(
    scan((state, action) => {
        switch(action.type) {
            case 'SET_OP': {
                return {
                    ...state,
                    operator: rectifyOps(state.operator, action.operator)
                }
            }

            case 'SET_DATE': {
                return {
                    ...state,
                    date: action.date
                }
            }

            case 'CLEAR_OP': {
                return {
                    ...state,
                    operator: null
                }
            }
            
            default: {
                return state
            };
        }
    }, initialState),
    startWith(initialState),
    shareReplay(1)
  );
  public params$: Observable<FilterParams> = this.state$.pipe(
    map(state => ({
        name: this.name as string,
        values: state.date && state.operator ? [{
            value: moment(state.date).format(this.format),
            operation: state.operator
        }]: []
    }))
);

  constructor(
    private name: keyof T,
    private format: string,
  ) {
    this.subscription.add(
        this.state$.subscribe()
    );
  }

  setOp(operator: Exclude<DateFilterOperator, '>=' | '<='>){
    this.actions$.next({
        type: 'SET_OP',
        operator
    })
  }

  clearOp(){
    this.actions$.next({
        type: 'CLEAR_OP',
    })
  }

  setDate(date: Date) {
    this.actions$.next({type: 'SET_DATE', date});
  }

  cleanup() {
    this.subscription.unsubscribe();
  }
}

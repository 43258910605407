import { Pipe, PipeTransform } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Pipe({
    name: 'dateStruct',
    pure: true,
    standalone: true
})

export class DateStructPipe implements PipeTransform {
    transform(value: Date | null | undefined): NgbDateStruct {
        const date = moment(value ?? undefined);
        return {
            year: date.get('year'),
            month: date.get('month') + 1,
            day: date.get('day')  
        }
    }
} 
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { APIEnvelope } from "src/app/shared/models/api";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  pagination,
  sort,
  withHttpParams,
  filter,
} from "src/app/shared/utilities/http-params";
import { MDMAJobStatus } from "./settlements.types";

@Injectable({
  providedIn: "root",
})
export class SettlementsService {
  constructor(private http: HttpClient) {}

  run(
    operatingDate: Date,
    settlementType: string,
    scheduleDate?: Date
  ): Observable<unknown> {
    return this.http.post("mdma_aggregation", {
      operating_day: moment(operatingDate).format("YYYY-MM-DD"),
      schedule_date: scheduleDate
        ? moment(scheduleDate).toISOString()
        : undefined,
      settlement_type: settlementType,
    });
  }

  getJobs(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ): Observable<APIEnvelope<MDMAJobStatus>> {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http.get<APIEnvelope<MDMAJobStatus>>("mdma_job_status", {
      params,
      headers: {
        NOLOADING: "true",
      },
    });
  }
}

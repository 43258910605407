import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

// define Cell Component to be reused
@Component({
    selector: 'app-table-action-cell',
    template: `
    @if(params.data && params.options && params.options.length ) {
        <mat-menu #menu="matMenu">
            @for (option of params.options; track $index) {
                <button mat-menu-item (click)="option.action(params.data)">{{option.label}}</button>
            }
        </mat-menu>
        
        <button  class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="more-horizontal"></mat-icon>
        </button>
    }
  `,
  styles: `
    .menu-button {
        border-radius: 0 !important;
        max-height: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;

        mat-icon {
            margin-bottom: 8px;
        }
    }
  `
 })
 export class TableActionCellComponent<T> implements ICellRendererAngularComp {
    params!: ICellRendererParams<T> & {
        options: {label: string, action: (data: T) => void}[]
    };
 
    agInit(params: ICellRendererParams<T> & {
        options: {label: string, action: (data: T) => void}[]
    }) {
        this.params = params;
    }
 
    refresh(params: ICellRendererParams<T> & {
        options: {label: string, action: (data: T) => void}[]
    }) {
        this.params = params;
        // As we have updated the params we return true to let AG Grid know we have handled the refresh.
        // So AG Grid will not recreate the cell renderer from scratch.
        return true;
    }
 }